<template>
	<!-- 我的收藏组件 -->
	<div id="track">
		<div class="headline">
			<span class="headline_track">收藏的商品</span
			><span class="headline_collect" @click="trackToCollect(9)">我的足迹</span>
		</div>
		<div class="showTrack">
			<div class="tasks" v-for="item in showList" :key="item.productListVO.id">
				<img
					:src="item.productListVO.coverUrl"
					alt=""
					@click="toGoodsDetail(item.productListVO.id)"
				/>
				<p class="taskTitle" v-html="item.productListVO.productName"></p>
				<p class="money">
					￥<span class="money_number">{{
						Number(item.productListVO.price).toFixed(2)
					}}</span>
				</p>
				<div class="tasksFooter">
					<span class="offCollect" @click="deleteTrack(item.productListVO.id)"
						>取消收藏</span
					>
					<span class="offCollect_after">|</span>
					<span class="addCart" @click="toGoodsDetail(item.productListVO.id)"
						>加入购物车</span
					>
				</div>
			</div>
		</div>
		<div class="hint" v-if="showList.length <= 0">用户暂无收藏商品~~</div>
		<!-- 分页 -->
		<div class="block" v-show="this.total >= 8">
			<Pages :total="total" :params="params" @jump="pageSkip"></Pages>
			<!-- <el-pagination @size-change="handleSizeChangeTrack" @current-change="handleCurrentChangeTrack" :page-size="8" layout="prev, pager, next, jumper" :totalTrack="totalTrack"> </el-pagination> -->
		</div>
	</div>
</template>

<script>
import Pages from '@/components/pages.vue'
export default {
	name: 'Track',
	components: {
		Pages,
	},
	data() {
		return {
			// 收藏的商品列表
			showList: [],
			// 用户token
			token: '',
			// 总条数
			total: 0,
			params: {
				token: this.$store.state.userData.token,
				page: 1,
				limit: 8,
			},
			// // 当前页
			// page:'1',
			// // 每页多少条
			// limit:"8",
		}
	},

	mounted() {
		this.trackInit()
	},

	methods: {
		handleSizeChangeTrack(val) {
			// console.log(`每页 ${val} 条`);
		},
		handleCurrentChangeTrack(val) {
			// console.log(`当前页: ${val}`);
			this.page = val
			// 切换页数发送请求
			this.trackInit()
		},
		// 查询用户收藏商品
		trackInit() {
			// 拿到用户token
			this.token = this.$store.state.userData.token
			// {
			//         token: this.token,
			//         page: this.page,
			//         limit: this.limit
			//     }
			this.api.findCollectionList(this.params).then((res) => {
				this.total = res.count
				this.showList = res.data
			})
		},
		//分页
		pageSkip(page) {
			this.params = page
			this.trackInit()
		},
		// 取消商品收藏
		deleteTrack(id) {
			this.api
				.delCollect({
					token: this.token,
					productId: id,
				})
				.then((res) => {
					this.trackInit()
				})
		},
		trackToCollect(dat) {
			// 向父组件传值
			this.$emit('changeAtt', dat)
		},
		// 进入商品详情页
		toGoodsDetail(id) {
			let routeData = this.$router.resolve({
				path: '/mall/goodsDetails',
				query: {
					id: id,
				},
			})
			window.open(routeData.href, '_blank')
		},
	},
}
</script>

<style lang="scss" scoped>
#track {
	min-height: 820px;
	width: 980px;
	background-color: white;
	position: relative;
	float: left;
	.headline {
		height: 60px;
		border-bottom: 1px solid #ededed;
		.headline_track {
			margin-left: 31px;
			margin-right: 20px;
			line-height: 56px;
			font-size: 16px;
			font-family: Microsoft YaHei;
			color: #e62129;
			display: inline-block;
			border-bottom: 2px solid red;
		}
		.headline_collect {
			line-height: 60px;
			font-size: 16px;
			font-family: Microsoft YaHei;
			cursor: pointer;
		}
	}
	.showTrack {
		.tasks {
			width: 220px;
			height: 331px;
			border: 1px solid #eaeaea;
			margin-top: 20px;
			float: left;
			margin-left: 12px;
			margin-right: 12px;
			cursor: pointer;
			img {
				width: 168px;
				height: 168px;
				margin-left: 24px;
				margin-top: 25px;
			}
			.taskTitle {
				margin-top: 18px;
				margin-left: 22px;
				width: 170px;
				height: 33px;
				font-size: 12px;
				color: #333333;
				// 溢出省略号
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
			.money {
				width: 74px;
				height: 15px;
				font-size: 18px;
				font-weight: bold;
				color: #e1251b;
				margin-left: 28px;
				margin-top: 16px;
			}
			.tasksFooter {
				width: 218px;
				height: 36px;
				background-color: #f4f4f4;
				margin-top: 19px;
				display: flex;
				color: #666666;
				.offCollect {
					flex: 1;
					text-align: center;
					line-height: 36px;
					cursor: pointer;
				}
				.offCollect_after {
					color: #eaeaea;
					line-height: 36px;
				}
				.addCart {
					flex: 1;
					text-align: center;
					line-height: 36px;
				}
			}
		}
	}
	.block {
		width: 100%;
		margin-top: 20px;
		text-align: center;
		position: absolute;
		bottom: 0;
		.el-pagination {
			display: inline-block;
		}
	}
	.hint {
		color: #909399;
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -10px;
		margin-left: -60px;
	}
}
</style>