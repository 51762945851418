var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"BillInfo"}},[_c('div',{staticClass:"headline"},[_c('span',{staticClass:"headline_info"},[_vm._v("发票信息")]),_c('span',{staticClass:"headline_rise",on:{"click":function($event){return _vm.billToRecord(16)}}},[_vm._v("开票记录")]),_c('button',{staticClass:"btn"},[_c('el-button',{staticClass:"button",staticStyle:{"color":"white","height":"34px"},attrs:{"type":"text"},on:{"click":function($event){_vm.dialogFormVisible = true}}},[_vm._v("新增发票抬头")]),_c('el-dialog',{attrs:{"title":"新增发票抬头","before-close":_vm.handleClose,"visible":_vm.dialogFormVisible},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-position":"right","rules":_vm.rules}},[_c('el-form-item',{staticClass:"resource",attrs:{"label":"发票类型:","prop":"resource"}},[_c('el-radio-group',{model:{value:(_vm.form.resource),callback:function ($$v) {_vm.$set(_vm.form, "resource", $$v)},expression:"form.resource"}},[_c('el-radio',{attrs:{"label":"个人发票"}}),_c('el-radio',{attrs:{"label":"企业发票"}}),_c('el-radio',{attrs:{"label":"增值税专用发票"}})],1)],1),_c('el-form-item',{attrs:{"label":"发票抬头:","prop":"name"}},[_c('el-input',{attrs:{"placeholder":"请输入发票抬头"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),(this.formFlag == '1' ? false : true)?_c('el-form-item',{staticClass:"region",attrs:{"label":"纳税人识别号:","prop":"region"}},[_c('el-input',{attrs:{"placeholder":"请输入纳税人识别号"},model:{value:(_vm.form.region),callback:function ($$v) {_vm.$set(_vm.form, "region", $$v)},expression:"form.region"}})],1):_vm._e(),(
							this.formFlag == '1'
								? false
								: this.formFlag == '2'
								? false
								: true
						)?_c('el-form-item',{attrs:{"label":"注册电话:","prop":"phone"}},[_c('el-input',{attrs:{"placeholder":"请输入注册电话"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1):_vm._e(),(
							this.formFlag == '1'
								? false
								: this.formFlag == '2'
								? false
								: true
						)?_c('el-form-item',{attrs:{"label":"注册地址:","prop":"address"}},[_c('el-input',{attrs:{"placeholder":"请输入注册地址"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1):_vm._e(),(
							this.formFlag == '1'
								? false
								: this.formFlag == '2'
								? false
								: true
						)?_c('el-form-item',{attrs:{"label":"开户银行:","prop":"BANK"}},[_c('el-input',{attrs:{"placeholder":"请输入开户银行"},model:{value:(_vm.form.BANK),callback:function ($$v) {_vm.$set(_vm.form, "BANK", $$v)},expression:"form.BANK"}})],1):_vm._e(),(
							this.formFlag == '1'
								? false
								: this.formFlag == '2'
								? false
								: true
						)?_c('el-form-item',{attrs:{"label":"银行账户:","prop":"bankAccount"}},[_c('el-input',{attrs:{"placeholder":"请输入银行账户"},model:{value:(_vm.form.bankAccount),callback:function ($$v) {_vm.$set(_vm.form, "bankAccount", $$v)},expression:"form.bankAccount"}})],1):_vm._e(),_c('el-form-item',[_c('el-checkbox',{attrs:{"label":"设为默认发票","name":"type"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.cancel}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.resetForm('form')}}},[_vm._v("确 定")])],1)],1)],1)]),_vm._m(0),_vm._l((_vm.invoiceList),function(item,index){return _c('div',{key:index,staticClass:"details_info"},[_c('div',{staticClass:"infoTitle"},[_vm._v(_vm._s(item.invoiceHead))]),_c('div',{staticClass:"bill_type"},[_vm._v(" "+_vm._s(item.invoiceType == 1 ? '个人发票' : item.invoiceType == 2 ? '企业发票' : '增值税专用发票')+" ")]),_c('div',{staticClass:"detail_info_open"},[_c('button',{staticClass:"alter",on:{"click":function($event){return _vm.handleEdit(item)}}},[_vm._v("修改")]),_c('span',[_vm._v("|")]),_c('button',{staticClass:"delete",on:{"click":function($event){return _vm.handleDelete(item.id)}}},[_vm._v("删除")]),_c('span',{class:item.defaultState == 1 ? 'default_back' : 'default',on:{"click":function($event){return _vm.handleDefault(item.id)}}},[_vm._v(_vm._s(item.defaultState == 1 ? '默认发票' : '设为默认'))])])])}),(_vm.invoiceList.length <= 0)?_c('div',{staticClass:"hint"},[_vm._v("用户暂无发票抬头~")]):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"billTitle"},[_c('span',{staticClass:"billTitle_details"},[_vm._v("发票抬头")]),_c('span',{staticClass:"billTitle_type"},[_vm._v("发票类型")]),_c('span',{staticClass:"billTitle_act"},[_vm._v("操作")])])}]

export { render, staticRenderFns }