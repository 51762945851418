<template>
	<div class="address_box">
		<div class="mask"></div>
		<div class="address_main">
			<div class="address_mai_head Between">
				<div class="title">{{ title }}</div>
				<i class="el-icon-close display pointer" @click="cancel('取消')"></i>
			</div>
			<div class="formData_box">
				<el-form
					:model="ruleForm"
					:rules="rules"
					ref="ruleForm"
					label-width="130px"
					class="demo-ruleForm"
				>
					<el-form-item label="发票类型：" prop="invoiceType">
						<div>
							<el-radio-group v-model="ruleForm.invoiceType">
								<el-radio :label="1">个人发票</el-radio>
								<el-radio :label="2">企业发票</el-radio>
								<el-radio :label="3">增值税专用发票</el-radio>
							</el-radio-group>
						</div>
					</el-form-item>
					<el-form-item label="发票抬头：" prop="invoiceHead">
						<el-input
							v-model="ruleForm.invoiceHead"
							placeholder="请输入发票抬头"
						></el-input>
					</el-form-item>
					<el-form-item
						label="纳税人识别号："
						prop="invoiceCode"
						v-if="ruleForm.invoiceType == 2 || ruleForm.invoiceType == 3"
					>
						<el-input
							v-model="ruleForm.invoiceCode"
							placeholder="请输入纳税人识别号"
						></el-input>
					</el-form-item>
					<el-form-item
						label="注册电话："
						prop="registerPhone"
						v-if="ruleForm.invoiceType == 3"
					>
						<el-input
							v-model="ruleForm.registerPhone"
							placeholder="请输入注册电话"
						></el-input>
					</el-form-item>
					<el-form-item
						label="注册地址："
						prop="registerAddress"
						v-if="ruleForm.invoiceType == 3"
					>
						<el-input
							v-model="ruleForm.registerAddress"
							placeholder="请输入册地址"
						></el-input>
					</el-form-item>
					<el-form-item
						label="开户银行："
						prop="depositaryBank"
						v-if="ruleForm.invoiceType == 3"
					>
						<el-input
							v-model="ruleForm.depositaryBank"
							placeholder="请输入开户银行"
						></el-input>
					</el-form-item>
					<el-form-item
						label="银行账户："
						prop="bankAccounts"
						v-if="ruleForm.invoiceType == 3"
					>
						<el-input
							v-model="ruleForm.bankAccounts"
							placeholder="请输入银行账户"
						></el-input>
					</el-form-item>
					<el-form-item label="">
						<el-checkbox v-model="checked">设为默认发票</el-checkbox>
					</el-form-item>
					<el-form-item>
						<div class="operation flex">
							<div
								class="btn display pointer saveBtn"
								@click="saveBtn('ruleForm')"
							>
								保存
							</div>
							<div
								class="btn display pointer cancleBtn"
								@click="cancel('取消')"
							>
								取消
							</div>
						</div>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		invoiceData: {},
		title: {},
		invoiceList: [],
	},
	data() {
		return {
			checked: true,
			ruleForm: {
				invoiceType: 1,
				invoiceHead: '',
				invoiceCode: '',
				registerPhone: '',
				registerAddress: '',
				depositaryBank: '',
				bankAccounts: '',
				defaultState: '',
				token: '',
			},
			rules: {
				invoiceType: [
					{ required: true, message: '请选择发票类型', trigger: 'change' },
				],
				invoiceHead: [
					{ required: true, message: '请输入发票抬头', trigger: 'blur' },
				],
				invoiceCode: [
					{ required: true, message: '请输入识别号', trigger: 'blur' },
					{
						min: 15,
						max: 20,
						message: '请输入15~20位纳税人识别号',
						trigger: 'blur',
					},
					{
						pattern: /^\d{15,20}$/,
						message: '请输入正确格式纳税人识别号',
						trigger: 'blur',
					},
				],
				registerPhone: [
					{ required: true, message: '请输入注册电话', trigger: 'blur' },
					{
						pattern: /^1[3|5|7|8|9]\d{9}$/,
						message: '请输入11位正确号码格式',
						trigger: 'change',
					},
				],
				registerAddress: [
					{ required: true, message: '请输入注册地址', trigger: 'blur' },
				],
				depositaryBank: [
					{ required: true, message: '请输入开户银行', trigger: 'blur' },
				],
				bankAccounts: [
					{ required: true, message: '请输入银行账户', trigger: 'blur' },
					{
						min: 16,
						max: 19,
						message: '请输入16~19位银行账户',
						trigger: 'blur',
					},
					{
						pattern: /^\d{16,19}$/,
						message: '请输入正确格式银行账户',
						trigger: 'blur',
					},
				],
			},
		}
	},
	mounted() {
		if (this.title == '修改发票') {
			this.ruleForm = this.invoiceData
			if (this.ruleForm.defaultState == 1) {
				this.checked = true
			} else {
				this.checked = false
			}
		} else {
			this.ruleForm = {
				invoiceType: 1,
				invoiceHead: '',
				invoiceCode: '',
				registerPhone: '',
				registerAddress: '',
				depositaryBank: '',
				bankAccounts: '',
				defaultState: '',
				token: '',
			}
		}
	},
	methods: {
		//取消
		cancel(name) {
			this.$emit('cancel', name)
		},
		// 保存
		saveBtn(formName) {
			this.ruleForm.token = this.$store.state.userData.token
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.checked == true) {
						this.ruleForm.defaultState = 1
					} else {
						this.ruleForm.defaultState = 2
					}
					if (this.title == '新增发票抬头') {
						this.add()
					} else {
						this.modify()
					}
					this.flag = 0
				} else {
					return false
				}
			})
		},
		//添加发票
		add() {
			this.api.insertInvoice(this.ruleForm).then((res) => {
				if (res.code == 0) {
					this.$message.success('添加成功')
					this.$emit('saveBtn')
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		//修改发票
		modify() {
			this.api.editInvoice(this.ruleForm).then((res) => {
				if (res.code == 0) {
					this.$message.success('修改成功')
					this.$emit('saveBtn')
				} else {
					this.$message.error(res.msg)
				}
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.address_box {
	.address_main {
		width: 724px;
		background: #ffffff;
		border-radius: 2px 2px 2px 2px;
		position: fixed;
		top: 50%;
		left: 50%;
		margin-top: -260px;
		margin-left: -362px;
		z-index: 99;
		.address_mai_head {
			width: 724px;
			height: 38px;
			background: #f4f4f4;
			padding-left: 30px;
			padding-right: 20px;
			.title {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
			}
			.el-icon-close {
				font-size: 24px;
				color: #8f8f8f;
			}
		}
		.formData_box {
			width: 100%;
			padding-top: 30px;
			padding-left: 53px;
			.el-input {
				width: 452px;
				height: 40px;
			}
			.el-cascader {
				width: 452px;
				height: 40px;
			}
			.el-form-item {
				margin-bottom: 20px;
			}

			.operation {
				.btn {
					width: 100px;
					height: 40px;
					border-radius: 3px;
					font-size: 18px;
				}
				.saveBtn {
					background: #e1251b;
					color: #ffffff;
					margin-right: 20px;
				}
				.cancleBtn {
					background: #f4f4f4;
					color: #333333;
				}
			}
		}
	}
}
</style>