<template>
    <!-- 我的优惠券组件 -->
    <div id="cheap">
        <div class="title">
            <span class="title_cheap">我的优惠券</span><span class="title_fetch" @click="toPlaza">领取更多优惠券</span>
        </div>
        <span class="title_rule" @click="dialogVisible = true">优惠券规则说明</span>
        <el-dialog title="优惠券使用说明" :visible.sync="dialogVisible" width="724px">
            <p class="explain">1、优惠券可与会员价同时使用</p>
            <p class="explain">
                2、优惠券不可与平台其他优惠活动（如满减、折扣价、秒杀等）同时使用
            </p>
            <p class="explain">3、优惠券不可与已签订的协议价格同时使用</p>
            <p class="explain">
                4、优惠券与优惠券之间的叠加使用，以券面标注的使用规则为准
            </p>
            <p class="explain">
                5、使用优惠券的订单如发生取消、退单等行为，退款仅退回实际现金支付的部分，整单或部分取消、退单，已使用的优惠券将不予返还
            </p>
            <p class="explain">
                6、使用优惠券的订单，果尔佳只对实际支付的金额进行开票，优惠券抵用的部分不予开票
            </p>
            <p class="explain">
                7、所有发放到会员账户的优惠券仅限本账号使用，不得转让
            </p>
            <p class="explain">8、筑手建筑防护超市保留对优惠券使用的最终解释权</p>
        </el-dialog>
                <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
            <el-tab-pane label="可用优惠券" name="first">
                            <div class="usable" v-for="item in unusedStatus" :key="item.id">
                                <!-- new Date(item.startDate).getTime() -->
                    <!-- top_red top_gray -->
                    <div :class="
                        							dateTime >= new Date(item.startDate).getTime()
    							    								? 'top_red'
    								    								: 'top_gray'
    						">
                        <p class="money">
                            ￥<span>{{ item.couponMoney }}</span>
                        </p>
                        <p class="money_rule">满{{ item.consumptionPrice }}可用</p>
                        <p class="time_rule">{{ item.startDate }}至{{ item.overDate }}</p>
                        <div></div>
                    </div>
                    <div class="usable_detail">
                        <p>编&ensp;&ensp;码：<span>202112471857534</span></p>
                        <p>类&ensp;&ensp;型：<span>全部商品都适用</span></p>
                                                <p>平&ensp;&ensp;台：<span>PC,APP</span></p>
                        <p>叠&ensp;&ensp;加：<span>不可与其他卷叠加</span></p>
                                                <p class="bottom">
                            <span class="bottom_one">规&ensp;&ensp;则：</span><span
                                class="bottom_tow">仅限企业客户，不可与其他优惠方式叠加</span>
                        </p>
                                           </div>
                                   <button @click="toGoodsList(item.id)">立即使用</button>
                </div>
                <div class="hint" v-show="unusedStatus.length <= 0">暂无可用优惠券</div>
            </el-tab-pane>
            <el-tab-pane label="已用优惠券" name="second">
                <div class="overdue" v-for="item in usedStatus" :key="item.id">
                    <div class="top_ash">
                        <p class="money">
                            ￥<span>{{ item.couponMoney }}</span>
                        </p>
                        <p class="money_rule">满{{ item.consumptionPrice }}可用</p>
                        <p class="time_rule">{{ item.startDate }}至{{ item.overDate }}</p>
                        <div><!-- 虚线 --></div>
                    </div>
                    <div class="overdue_detail">
                                            <p>编&ensp;&ensp;码：<span>202112471857534</span></p>
                                                <p>类&ensp;&ensp;型：<span>全部商品都适用</span></p>
                        <p>平&ensp;&ensp;台：<span>PC,APP</span></p>
                        <p>叠&ensp;&ensp;加：<span>不可与其他卷叠加</span></p>
                        <p class="bottom">
                            <span class="bottom_one">规&ensp;&ensp;则：</span><span
                                class="bottom_tow">仅限企业客户，不可与其他优惠方式叠加</span>
                        </p>
                    </div>
                    <button>已使用</button>
                </div>
                <div class="hint" v-show="usedStatus.length <= 0">暂无已用优惠券</div>
            </el-tab-pane>
            <el-tab-pane label="过期优惠券" name="third">
                            <div class="overdue" v-for="item in overdueStatus" :key="item.id">
                    <div class="top_ash">
                        <p class="money">
                            ￥<span>{{ item.couponMoney }}</span>
                        </p>
                        <p class="money_rule">满{{ item.consumptionPrice }}可用</p>
                        <!-- .substring(0,10)  -->
                        <p class="time_rule">{{ item.startDate }}至{{ item.overDate }}</p>
                        <div><!-- 虚线 --></div>
                    </div>
                    <div class="overdue_detail">
                        <p>编&ensp;&ensp;码：<span>202112471857534</span></p>
                        <p>类&ensp;&ensp;型：<span>全部商品都适用</span></p>
                        <p>平&ensp;&ensp;台：<span>PC,APP</span></p>
                        <p>叠&ensp;&ensp;加：<span>不可与其他卷叠加</span></p>
                        <p class="bottom">
                            <span class="bottom_one">规&ensp;&ensp;则：</span><span
                                class="bottom_tow">仅限企业客户，不可与其他优惠方式叠加</span>
                        </p>
                    </div>
                    <button>已过期</button>
                </div>
                <div class="hint" v-show="overdueStatus.length <= 0">
                    暂无过期优惠券
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
export default {
    name: 'Cheap',
    data() {
        return {
            dialogVisible: false,
            activeName: 'first',
            // 未使用
            unusedStatus: [],
            // 已使用
            usedStatus: [],
            // 已过期
            overdueStatus: [],
            // 优惠券状态
            status: '0',
            // 当前时间时间戳
            dateTime: '',
        }
    },
    mounted() {
        this.cheapInit()
        // 当前时间时间戳
        this.dateTime = new Date().getTime()
    },

    methods: {
        // 时间格式转化
        recentTime(day, fmt, time) {
            //获取当前时间的毫秒值
            let now = (time ? new Date(time) : new Date()).getTime()
            // 获取前后n天时间
            let recent = new Date(now + day * 24 * 60 * 60 * 1000)
            // key：正则匹配表达式，value：对应的时间、日期
            let fmtObj = {
                'M+': recent.getMonth() + 1, //月份
                'd+': recent.getDate(), //日
                'h+': recent.getHours(), //时
                'm+': recent.getMinutes(), //分
                's+': recent.getSeconds(), //秒
            }
            // 获取匹配年份替换
            if (/(y+)/.test(fmt)) {
                //RegExp.$1 匹配结果，替换成对应的长度。如：yyyy就替换成整个年份2021，yy就替换成后两位21，以此类推
                fmt = fmt.replace(
                    RegExp.$1,
                    (recent.getFullYear() + '').substr(4 - RegExp.$1.length)
                )
            }
            for (let key in fmtObj) {
                if (new RegExp(`(${key})`).test(fmt)) {
                    //日期，时、分、秒替换，判断fmt是否需要补0，如：yyyy-M-d h:m:s 不补0,yyyy-MM-dd hh:mm:ss 则自动补0
                    fmt = fmt.replace(
                        RegExp.$1,
                        RegExp.$1.length == 1
                            ? fmtObj[key]
                            : ('00' + fmtObj[key]).substr(('' + fmtObj[key]).length)
                    )
                }
            }
            return fmt
        },
        // 查询用户优惠券
        cheapInit() {
            this.api
                .queryByUserId({
                    token: this.$store.state.userData.token,
                    status: this.status,
                })
                .then((res) => {
                    if (res.data) {
                        let data = JSON.parse(JSON.stringify(res.data))
                        data.forEach((item) => {
                            if (item.takeEffect == 2) {
                                item.startDate = item.gmtCreate.substring(0, 10)
                                item.overDate = this.recentTime(
                                    item.validDay,
                                    'yyyy-MM-dd',
                                    item.gmtCreate
                                )
                            }
                        })
                        if (this.status == 0) {
                            // console.log('可使用',data)
                            this.unusedStatus = data
                        } else if (this.status == 1) {
                            // console.log('已使用',data)
                            this.usedStatus = data
                        } else {
                            // console.log('过期',data)
                            this.overdueStatus = data
                        }
                    }
                })
        },
        // tab切换
        handleClick(tab) {
            if (tab.name == 'first') {
                this.status = 0
                this.cheapInit()
            } else if (tab.name == 'second') {
                this.status = 1
                this.cheapInit()
            } else {
                this.status = 2
                this.cheapInit()
            }
        },
        // 领劵广场
        toPlaza() {
            let routeData = this.$router.resolve({ path: '/plaza/index' })
            window.open(routeData.href, '_blank')
        },
        // 点击使用跳转商品列表
        toGoodsList(id) {
            this.$router.push({
                path: '/mall/goodsList',
                query: {
                    coupon: id,
                },
            })
            window.open(routeData.href, '_blank')
        },
    },
}
</script>

<style lang="scss" scoped>
#cheap {
    // height: 880px;
    background-color: white;
    position: relative;

    ::v-deep.el-dialog__wrapper {
        .el-dialog {
            height: 449px;

            .el-dialog__header {
                padding-top: 10px;
                background: #f4f4f4;

                .el-dialog__headerbtn {
                    top: 13px;

                    .el-dialog__close::before {
                        font-size: 20px;
                    }
                }
            }

            .el-dialog__body {
                padding-left: 40px;

                .explain {
                    line-height: 30px;
                }
            }
        }
    }

    .title {
        height: 60px;
        border-bottom: 1px solid #ededed;
        margin-bottom: 17px;

        .title_cheap {
            margin-left: 41px;
            line-height: 60px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            color: #e62129;
        }

        .title_fetch {
            cursor: pointer;
            padding: 10px;
            background: #e1251b;
            border-radius: 3px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            margin-left: 695px;
        }
    }

    .title_rule {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #336699;
        margin-left: 735px;
        cursor: pointer;
        position: absolute;
        z-index: 88;
        top: 89px;
        right: 50px;
    }

    ::v-deep.el-tabs {
        border: none;
        box-shadow: none;
        width: 917px;
        margin: 0 auto;

        .el-tabs__header {
            background-color: #f4f4f4;

            .el-tabs__nav-wrap {
                background-color: #f4f4f4;

                .el-tabs__nav-scroll {
                    background-color: #f4f4f4;

                    .el-tabs__nav {
                        .is-active {
                            border: none;
                            color: #e62129;
                            background-color: #f4f4f4;
                        }

                        .el-tabs__item {
                            border: none;
                            transition: none;
                        }

                        .el-tabs__item:hover {
                            color: #e62129;
                        }
                    }
                }
            }
        }

        .el-tabs__content {
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: 0;
            padding-right: 0;

            .el-tab-pane {
                float: left;
                width: 100%;

                // 已过期劵样式
                .overdue {
                    width: 210px;
                    height: 387px;
                    background-color: #f9f9f9;
                    float: left;
                    margin-left: 9px;
                    margin-right: 10px;

                    .top_ash {
                        height: 120px;
                        background: linear-gradient(0deg, #d0d0d0, #eaeaea);
                        position: relative;

                        div {
                            width: 100%;
                            height: 1px;
                            position: absolute;
                            bottom: -2px;
                            border-bottom: 4px dotted #d0d0d0;
                        }

                        .money {
                            text-align: center;
                            height: 30px;
                            font-size: 17px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #ffffff;
                            line-height: 31px;
                            margin: 0 auto;
                            padding-top: 25px;

                            span {
                                font-size: 35px;
                            }
                        }

                        .money_rule {
                            text-align: center;
                            margin: 0 auto;
                            margin-top: 11px;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #ffffff;
                            line-height: 18px;
                        }

                        .time_rule {
                            text-align: center;
                            margin: 0 auto;
                            margin-top: 13px;
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #ffffff;
                            line-height: 18px;
                        }
                    }

                    .overdue_detail {
                        width: 170px;
                        height: 150px;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin: 0 auto;
                        margin-top: 21px;

                        .bottom {
                            display: flex;

                            .bottom_one {
                                flex: 1;
                            }

                            .bottom_tow {
                                flex: 2.5;
                            }
                        }
                    }

                    button {
                        display: inline-block;
                        width: 120px;
                        height: 34px;
                        background: #f9f9f9;
                        border: 1px solid #d1d1d1;
                        border-radius: 3px;

                        margin-top: 19px;
                        margin-left: 45px;
                        cursor: pointer;

                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #d1d1d1;
                    }
                }

                .hint {
                    width: 100%;
                    background: #f4f4f4;
                    text-align: center;
                    color: #909399;
                }

                // 已使用劵样式
                // 能使用劵样式
                .usable {
                    width: 210px;
                    height: 387px;
                    background-color: #f9f9f9;
                    float: left;
                    margin-left: 9px;
                    margin-right: 10px;

                    .top_gray {
                        height: 120px;
                        position: relative;
                        background: linear-gradient(0deg, #eaeaea, #d0d0d0);

                        div {
                            width: 100%;
                            height: 1px;
                            position: absolute;
                            bottom: -2px;
                            border-bottom: 4px dotted #eaeaea;
                        }

                        .money {
                            text-align: center;
                            height: 30px;
                            font-size: 17px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #ffffff;
                            line-height: 31px;
                            margin: 0 auto;
                            padding-top: 25px;

                            span {
                                font-size: 35px;
                            }
                        }

                        .money_rule {
                            text-align: center;
                            margin: 0 auto;
                            margin-top: 11px;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #ffffff;
                            line-height: 18px;
                        }

                        .time_rule {
                            text-align: center;
                            margin: 0 auto;
                            margin-top: 13px;
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #ffffff;
                            line-height: 18px;
                        }
                    }

                    .top_red {
                        height: 120px;
                        background: linear-gradient(0deg, #ff7071, #ff4a53);
                        position: relative;

                        div {
                            width: 100%;
                            height: 1px;
                            position: absolute;
                            bottom: -2px;
                            border-bottom: 4px dotted #ff7071;
                        }

                        .money {
                            text-align: center;
                            height: 30px;
                            font-size: 17px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #ffffff;
                            line-height: 31px;
                            margin: 0 auto;
                            padding-top: 25px;

                            span {
                                font-size: 35px;
                            }
                        }

                        .money_rule {
                            text-align: center;
                            margin: 0 auto;
                            margin-top: 11px;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #ffffff;
                            line-height: 18px;
                        }

                        .time_rule {
                            text-align: center;
                            margin: 0 auto;
                            margin-top: 13px;
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #ffffff;
                            line-height: 18px;
                        }
                    }

                    .usable_detail {
                        width: 170px;
                        height: 150px;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin: 0 auto;
                        margin-top: 21px;

                        .bottom {
                            display: flex;

                            .bottom_one {
                                flex: 1;
                            }

                            .bottom_tow {
                                flex: 2.5;
                            }
                        }
                    }

                    button {
                        display: inline-block;
                        width: 120px;
                        height: 34px;
                        background: #f9f9f9;
                        border: 1px solid #e62129;
                        border-radius: 3px;

                        margin-top: 19px;
                        margin-left: 45px;
                        cursor: pointer;

                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ff4d55;
                    }
                }
            }
        }
    }
}</style>