<template>
	<div>
		<!-- 商品分类 -->
		<Classification
			:types="1"
			:navData="$store.state.navData"
			:broadside="$store.state.broadside"
		></Classification>
		<div class="div">
			<div class="CoreMain">
				<!-- 左侧菜单 -->
				<div class="userCore_left">
					<p @click="changeAccount(null)">用户中心</p>

					<div class="userCore_left_li">
						<div>
							<img src="../../assets/img/person/indent.png" alt="" />
							<span class="text">订单中心</span>
						</div>
						<div class="indexText_children">
							<p
								:class="
									$store.state.broadside.isShow == item.id ? 'textColor' : ''
								"
								@click="changeBtn(item.id)"
								v-for="item in indent"
								:key="item.id"
							>
								{{ item.name }}
							</p>
						</div>
					</div>

					<div class="userCore_left_li">
						<div>
							<img src="../../assets/img/person/indent.png" alt="" />
							<span class="text">我关注的</span>
						</div>
						<div class="indexText_children">
							<!-- :class="(isColor =='track' && item.id ==8)||item.id ==isShow?'textColor':(isColor =='collect' && item.id ==9)||item.id ==isShow?'textColor':''" -->
							<p
								:class="
									$store.state.broadside.isShow == item.id ? 'textColor' : ''
								"
								@click="changeAttention(item.id)"
								v-for="item in attention"
								:key="item.id"
							>
								{{ item.name }}
							</p>
						</div>
					</div>
					<div class="userCore_left_li">
						<div>
							<img src="../../assets/img/person/indent.png" alt="" />
							<span class="text">发票管理</span>
						</div>
						<div class="indexText_children">
							<p
								:class="
									$store.state.broadside.isShow == item.id ? 'textColor' : ''
								"
								@click="changeBill(item.id)"
								v-for="item in bill"
								:key="item.id"
							>
								{{ item.name }}
							</p>
						</div>
					</div>
					<div class="userCore_left_li">
						<div>
							<img src="../../assets/img/person/indent.png" alt="" />
							<span class="text">账号中心</span>
						</div>
						<div class="indexText_children">
							<p
								:class="
									$store.state.broadside.isShow == item.id ? 'textColor' : ''
								"
								@click="changeAccount(item.id)"
								v-for="item in userInfo"
								:key="item.id"
							>
								{{ item.name }}
							</p>
						</div>
					</div>
				</div>
				<!-- 右侧显示区 -->
				<div class="userCore_right">
					<!-- <Idea /> -->
					<div
						class="userCenter_box"
						v-if="$store.state.broadside.isShow == null"
					>
						<UserCenter
							:orderType="orderType"
							@changeBtn="changeBtn"
							@changePBTC="changePBTC"
							@changeApprove="changeApprove"
						/>
					</div>
					<OrderCenter
						v-if="
							$store.state.broadside.isShow == 99 ||
							$store.state.broadside.isShow == 1 ||
							$store.state.broadside.isShow == 2 ||
							$store.state.broadside.isShow == 3 ||
							$store.state.broadside.isShow == 4
						"
						:orderType="orderType"
					/>
					<ReturnGoodsList v-if="$store.state.broadside.isShow == 7" />

					<Track
						v-if="$store.state.broadside.isShow == 8"
						v-on:changeAtt="changeAtt"
					/>
					<Collect
						v-if="$store.state.broadside.isShow == 9"
						v-on:changeAtt="changeAtt"
					/>
					<Cheap v-if="$store.state.broadside.isShow == 10" />
					<Part v-if="$store.state.broadside.isShow == 11" />
					<Bonus v-if="$store.state.broadside.isShow == 12" />
					<IPam v-if="$store.state.broadside.isShow == 13" />
					<Friend v-if="$store.state.broadside.isShow == 14" />

					<BillInfo
						v-if="$store.state.broadside.isShow == 15"
						v-on:changeBillIR="changeBillIR"
					/>
					<BillRecord
						v-if="$store.state.broadside.isShow == 16"
						v-on:changeBillIR="changeBillIR"
					/>

					<Safety
						v-if="$store.state.broadside.isShow == 17"
						:safetyFlag="safetyFlag"
					/>
					<Approve
						v-if="$store.state.broadside.isShow == 18"
						:activeTab="activeTab"
					/>
					<Idea v-if="$store.state.broadside.isShow == 19" />
					<Service v-if="$store.state.broadside.isShow == 20" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Classification from '@/components/classification.vue'
// 商品收藏组件
import Track from '@/views/person/attention/track.vue'
// 我的足迹组件(高度不足850)
import Collect from '@/views/person/attention/collect.vue'
// 我的积分组件
import Part from '@/views/person/attention/part.vue'
// 我的奖金组件
import Bonus from '@/views/person/attention/bonus.vue'
// 我的优惠券组件
import Cheap from '@/views/person/attention/cheap.vue'
// 地址管理
import IPam from '@/views/person/attention/IPam.vue'
// 邀请好友组件
import Friend from '@/views/person/attention/friend.vue'

// 开票记录组件(需要改动tab选项)
import BillRecord from '@/views/person/bill/billRecord.vue'
// 发票信息组件(需要改动tab选项)
import BillInfo from '@/views/person/bill/billInfo.vue'

// 账号与安全组件
import Safety from '@/views/person/account/safety.vue'
// 认证信息组件
import Approve from '@/views/person/account/approve.vue'
// 联系客服组件
import Service from '@/views/person/account/service.vue'
// 意见反馈组件
import Idea from '@/views/person/account/idea.vue'
// 个人中心
import UserCenter from '../userInfo/components/userCenter.vue'
// 订单中心
import OrderCenter from '../userInfo/components/orderCenter.vue'
import ReturnGoodsList from '../userInfo/components/returnGoodsList.vue'
export default {
	components: {
		Classification,
		Track,
		Collect,
		BillRecord,
		BillInfo,
		Part,
		Bonus,
		Cheap,
		Approve,
		IPam,
		Service,
		Idea,
		Friend,
		Safety,
		UserCenter,
		OrderCenter,
		ReturnGoodsList,
	},
	data() {
		return {
			//显示隐藏
			isShow: null,
			// 订单状态
			orderType: '',
			// 待付款
			payment: '1',
			// 待发货
			sendShipping: '8',
			// 待收货
			obtainShipping: '12',
			// 退换/售后
			sellServe: '0',
			// 积分
			calculus: '500',
			// 优惠券
			onSale: '7',
			// 收藏
			collect: '16',
			// 头像
			headImgUrl: '',
			// 昵称
			nickname: 'HI,810798604_m',
			// 订单中心
			indent: [
				{
					id: '99',
					name: '全部订单',
				},
				{
					id: 1,
					name: '待付款',
				},
				{
					id: 2,
					name: '待发货',
				},
				{
					id: 3,
					name: '待收货',
				},
				{
					id: 4,
					name: '待评价',
				},
				{
					id: 7,
					name: '退货/售后',
				},
			],
			// 我关注的
			attention: [
				{
					id: 8,
					name: '我的收藏',
				},
				{
					id: 9,
					name: '我的足迹',
				},
				{
					id: 10,
					name: '我的优惠券',
				},
				{
					id: 11,
					name: '我的积分',
				},
				{
					id: 12,
					name: '我的奖金',
				},
				{
					id: 13,
					name: '地址管理',
				},
				{
					id: 14,
					name: '邀请好友',
				},
			],
			// 发票管理
			bill: [
				{
					id: 15,
					name: '发票信息',
				},
				{
					id: 16,
					name: '开票记录',
				},
			],
			// 账号中心
			userInfo: [
				{
					id: 17,
					name: '账号与安全',
				},
				{
					id: 18,
					name: '认证信息',
				},
				{
					id: 19,
					name: '意见反馈',
				},
				{
					id: 20,
					name: '联系客服',
				},
			],
			// 传值-账号与安全组件
			safetyFlag: false,
			// 传值-认证组件tab切换
			activeTab: 'second',
		}
	},
	mounted() {
		if (this.$store.state.broadside.isShow) {
			this.orderType = this.$store.state.broadside.isShow
			this.isShow = this.$store.state.broadside.isShow
		} else {
			this.isShow = this.$route.query.type
			this.orderType = this.$route.query.type
		}
		let data = {
			isShow: this.isShow,
		}

		sessionStorage.setItem('broadside', JSON.stringify(data))
		this.$store.commit('getBroadside', data)
	},
	beforeDestroy() {
		//离开页面清除数据
		sessionStorage.removeItem('broadside')
		this.$store.commit('getBroadside', '')
	},
	methods: {
		//回到顶部
		toTop() {
			window.scrollTo(0, 0)
		},
		//点击订单中心
		changeBtn(id) {
			let data = {
				isShow: id,
			}
			sessionStorage.setItem('broadside', JSON.stringify(data))
			this.$store.commit('getBroadside', data)
			this.orderType = id
			this.toTop()
		},
		// 点击我的关注
		changeAttention(id) {
			let data = {
				isShow: id,
			}
			sessionStorage.setItem('broadside', JSON.stringify(data))
			this.$store.commit('getBroadside', data)
			this.toTop()
		},
		changeAtt: function (dat) {
			let data = {
				isShow: dat,
			}
			sessionStorage.setItem('broadside', JSON.stringify(data))
			this.$store.commit('getBroadside', data)
			this.toTop()
		},
		// 点击发票管理
		changeBill(id) {
			let data = {
				isShow: id,
			}
			sessionStorage.setItem('broadside', JSON.stringify(data))
			this.$store.commit('getBroadside', data)
			this.toTop()
		},
		changeBillIR: function (dat) {
			let data = {
				isShow: dat,
			}
			sessionStorage.setItem('broadside', JSON.stringify(data))
			this.$store.commit('getBroadside', data)
			this.toTop()
		},
		// 点击账号安全
		changeAccount(id) {
			let data = {
				isShow: id,
			}
			sessionStorage.setItem('broadside', JSON.stringify(data))
			this.$store.commit('getBroadside', data)
			if (this.$store.state.broadside.isShow == 17) {
				// 刷新页面
				this.$router.go(0)
			}
			this.toTop()
		},
		//
		changePBTC(id) {
			let data = {
				isShow: id,
			}
			sessionStorage.setItem('broadside', JSON.stringify(data))
			this.$store.commit('getBroadside', data)
			this.toTop()
		},
		//
		changeApprove(id, dat) {
			let data = {
				isShow: id,
			}
			if (dat != undefined && dat != null) {
				this.activeTab = dat
			}
			sessionStorage.setItem('broadside', JSON.stringify(data))
			this.$store.commit('getBroadside', data)
			this.toTop()
		},
	},
}
</script>

<style scoped>
.div {
	min-width: 1200px;
	margin: 0 auto;
}
.div .CoreMain {
	width: 1200px;
	margin: 0 auto;
	/* min-height: 826px; */
	display: flex;
}
.div .CoreMain .userCore_left {
	background-color: white;
	width: 200px;
	/* min-height: 850px; */
	height: 880px;
	margin-top: 20px;
	margin-bottom: 30px;
	/* display: inline-block; */
	margin-right: 20px;
}
.div .CoreMain .userCore_left > p {
	width: 200px;
	height: 60px;
	font-size: 24px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #333333;
	line-height: 60px;
	padding-left: 26px;
	box-sizing: border-box;
	border-bottom: 1px solid rgba(234, 234, 234, 1);
	cursor: pointer;
}
.div .CoreMain .userCore_left .userCore_left_li {
	margin-top: 19px;
	margin-bottom: 10px;
}
.div .CoreMain .userCore_left .userCore_left_li > div {
	margin-left: 32px;
}
.div .CoreMain .userCore_left .userCore_left_li > div > img {
	width: 15px;
	height: 16px;
	display: inline-block;
	position: relative;
	top: 2px;
	margin-right: 9px;
}
.div .CoreMain .userCore_left .userCore_left_li > div .text {
	width: 66px;
	height: 17px;
	font-size: 16px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #333333;
	display: inline-block;
}
.div .CoreMain .userCore_left .userCore_left_li .indexText_children {
	margin-left: 57px;
	margin-top: 20px;
}
.div .CoreMain .userCore_left .userCore_left_li .indexText_children .textColor {
	color: red;
}
.div .CoreMain .userCore_left .userCore_left_li .indexText_children p {
	display: block;
	font-size: 14px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #666666;
	line-height: 30px;
	cursor: pointer;
}
.div .CoreMain .userCore_right {
	display: inline-block;
	width: 980px;
	/* min-height: 450px; */
	margin-top: 20px;
	margin-bottom: 30px;
	background: white;
}
.div .CoreMain .userCore_right .core {
	margin-bottom: 20px;
}
.div .CoreMain .userCore_right .core > p {
	height: 60px;
	font-size: 18px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #e1251b;
	line-height: 60px;
	box-sizing: border-box;
	padding-left: 30px;
	border-bottom: 1px solid rgba(234, 234, 234, 1);
	background-color: white;
}
.div .CoreMain .userCore_right .core .core_user {
	width: 100%;
	height: 196px;
	background-color: sandybrown;
	display: flex;
}
.div .CoreMain .userCore_right .core .core_user .core_user_left {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	align-content: center;
	border-right: 1px solid rgba(234, 234, 234, 1);
}
.div .CoreMain .userCore_right .core .core_user .core_user_left .headImgUrl {
	width: 118px;
	height: 118px;
	margin-left: 37px;
	border-radius: 50%;
	overflow: hidden;
}
.div .CoreMain .userCore_right .core .core_user .core_user_left .nickname {
	margin-left: 30px;
}
.div .CoreMain .userCore_right .core .core_user .core_user_left .nickname div {
	width: 226px;
	display: flex;
}
.div
	.CoreMain
	.userCore_right
	.core
	.core_user
	.core_user_left
	.nickname
	div
	div:nth-child(1) {
	width: 108px;
	height: 30px;
	background: #f29a00;
	border-radius: 15px;
	margin-top: 10px;
	margin-right: 10px;
}
.div
	.CoreMain
	.userCore_right
	.core
	.core_user
	.core_user_left
	.nickname
	div
	div:nth-child(1)
	img,
.div
	.CoreMain
	.userCore_right
	.core
	.core_user
	.core_user_left
	.nickname
	div
	div:nth-child(2)
	img {
	display: inline-block;
	width: 19px;
	height: 22px;
	margin-left: 14px;
	margin-top: 4px;
	margin-right: 6px;
}
.div
	.CoreMain
	.userCore_right
	.core
	.core_user
	.core_user_left
	.nickname
	div
	div:nth-child(1)
	span,
.div
	.CoreMain
	.userCore_right
	.core
	.core_user
	.core_user_left
	.nickname
	div
	div:nth-child(2)
	span {
	width: 56px;
	height: 14px;
	font-size: 14px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #ffffff;
	margin-top: 4px;
}
.div
	.CoreMain
	.userCore_right
	.core
	.core_user
	.core_user_left
	.nickname
	div
	div:nth-child(2) {
	width: 108px;
	height: 30px;
	background: #0068c1;
	border-radius: 15px;
	margin-top: 10px;
}
.div .CoreMain .userCore_right .core .core_user .core_user_left,
.div .CoreMain .userCore_right .core .core_user .core_user_right {
	background-color: white;
	flex: 1;
}
.div .CoreMain .userCore_right .core .core_user .core_user_left > img {
	width: 118px;
	height: 118px;
}
.div .CoreMain .userCore_right .core .core_user .core_user_right {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-content: center;
	align-items: center;
}
.div .CoreMain .userCore_right .core .core_user .core_user_right .calcul,
.div .CoreMain .userCore_right .core .core_user .core_user_right .coll {
	width: 32px;
	height: 17px;
	font-size: 16px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #666666;
	line-height: 48px;
}
.div .CoreMain .userCore_right .core .core_user .core_user_right .sale {
	width: 48px;
	height: 16px;
	font-size: 16px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #666666;
	line-height: 48px;
}
.div
	.CoreMain
	.userCore_right
	.core
	.core_user
	.core_user_right
	.coreUserRight_children {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	align-content: center;
	flex-direction: column;
	justify-content: center;
}
.div
	.CoreMain
	.userCore_right
	.core
	.core_user
	.core_user_right
	.coreUserRight_children
	.Number {
	font-size: 24px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #333333;
}
.div .CoreMain .userCore_right .iOrder {
	background-color: white;
	min-height: 170px;
	overflow: hidden;
}
.div .CoreMain .userCore_right .iOrder > p {
	width: 72px;
	height: 18px;
	font-size: 18px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #333333;
	margin-left: 30px;
	margin-top: 28px;
	margin-bottom: 19px;
}
.div .CoreMain .userCore_right .iOrder .allIndent {
	margin-left: 30px;
	width: 918px;
	height: 83px;
	border-left: 1px solid #eaeaea;
	border-top: 1px solid #eaeaea;
	border-bottom: 1px solid #eaeaea;
	display: flex;
}
.div .CoreMain .userCore_right .iOrder .allIndent .allIndent_children {
	flex: 1;
	border-right: 1px solid #eaeaea;
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
	justify-content: flex-start;
}
.div .CoreMain .userCore_right .iOrder .allIndent .all {
	font-size: 18px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #333333;
	text-align: center;
	line-height: 81px;
	flex: 1;
	border-right: 1px solid #eaeaea;
}
.div
	.CoreMain
	.userCore_right
	.iOrder
	.allIndent
	.allIndent_children
	.allIndent_img {
	width: 50px;
	height: 50px;
	margin-left: 30px;
	margin-right: 23px;
}
.div
	.CoreMain
	.userCore_right
	.iOrder
	.allIndent
	.allIndent_children
	.allIndent_text
	.font {
	font-size: 14px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #333333;
}
.div
	.CoreMain
	.userCore_right
	.iOrder
	.allIndent
	.allIndent_children
	.allIndent_text
	.fontNumber {
	font-size: 24px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #333333;
}
</style>