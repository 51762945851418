<template>
	<!-- 账号与安全组件 -->
	<div id="safety">
		<div class="safety_title">
			<span>账号与安全</span>
		</div>
		<div v-if="flag">
			<div class="safety_body">
				<div class="image" @mouseover="mouseOver">
					<img :src="imageUrl" alt="" />
					<userImg
						:class="flagChange ? 'changeImg' : 'changeImg none'"
						@uploadImage="uploadImage"
					/>
				</div>
				<div class="user_info">
					<p>
						注册手机号:<span>{{
							this.oldPhone.substr(0, 3) + '****' + this.oldPhone.substr(7)
						}}</span>
					</p>
					<el-form
						:model="ruleForm"
						:rules="rules"
						ref="ruleForm"
						label-width="100px"
						class="demo-ruleForm"
					>
						<el-form-item label="性　　　别:" prop="sex">
							<el-radio-group v-model="ruleForm.sex">
								<el-radio label="男"></el-radio>
								<el-radio label="女"></el-radio>
								<el-radio label="保密"></el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="昵　　　称:" prop="name">
							<el-input v-model="ruleForm.name"></el-input>
						</el-form-item>
						<el-form-item label="邮　　　箱:" prop="email">
							<el-input v-model="ruleForm.email"></el-input>
						</el-form-item>

						<el-form-item>
							<el-button type="primary" @click="submitForm('ruleForm')"
								>重新提交</el-button
							>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<div v-if="flag" class="safety_footer">
			<div class="password">
				<img src="../../../assets/img/person/ganTanHao.png" alt="" />
				<p class="password_title">登录密码</p>
				<p class="password_text">
					互联网账号存在被盗风险，建议您定期更改密码以保护账户安全。
				</p>
				<p class="password_btn" @click="toAlterPassword">修改</p>
			</div>
			<div class="prove">
				<img src="../../../assets/img/person/duiHao.png" alt="" />
				<p class="prove_title">手机验证</p>
				<p class="prove_text">
					您验证的手机：
					<span>{{
						this.oldPhone.substr(0, 3) + '****' + this.oldPhone.substr(7)
					}}</span
					>　 若已丢失或停用，请立即更换。
				</p>
				<p class="prove_btn" @click="alterPhone">修改</p>
			</div>
		</div>
		<!-- 更换手机号组件 -->
		<div class="steps" v-else>
			<el-steps :active="active" finish-status="success">
				<el-step title="身份验证"></el-step>
				<el-step title="更换手机号"></el-step>
				<el-step title="完成"></el-step>
			</el-steps>

			<!-- 步骤内容 -->
			<div class="step_one" v-if="active == 0">
				<div class="phone_number">
					当前手机号:<span>{{ this.oldPhone }}</span>
				</div>
				<div class="note">
					短信验证码:
					<input
						type="text"
						v-model="oldPhoneCode"
						placeholder="请输入短信验证码"
					/>
					<div v-if="isCode" class="item_error">请输入正确格式验证码</div>
					<a
						class="sendMsg pointer display"
						v-show="Verification == 1"
						@click="getCodeBtn1"
						>获取验证码</a
					>
					<a class="sendMsg pointer display" v-show="Verification == 2">
						<span>{{ timer }}</span
						>秒后重新获取
					</a>
				</div>
			</div>

			<div class="step_tow" v-if="active == 1">
				<div class="phone_number_new">
					<div class="label flex">
						<div class="required">*</div>
						新手机号码:
					</div>
					<input
						class="find_form_list_input"
						v-model="newPhone"
						maxlength="11"
						type="text"
						placeholder="请输入手机号"
					/>
					<div v-if="isPhone" class="item_error">请正确输入手机号</div>
				</div>
				<div class="note">
					短信验证码:
					<input
						type="text"
						v-model="newPhoneCode"
						placeholder="请输入短信验证码"
					/>
					<div v-if="isCode" class="item_error">请输入正确格式验证码</div>
					<a
						class="sendMsg pointer display"
						v-show="Verifications == 1"
						@click="getCodeBtn2"
						>获取验证码</a
					>
					<a class="sendMsg pointer display" v-show="Verifications == 2">
						<span>{{ timers }}</span
						>秒后重新获取
					</a>
				</div>
			</div>

			<div class="step_three" v-if="active == 2">
				<p>您的新手机号码已更换成功!</p>
			</div>
			<el-button style="margin-top: 32px" @click="next">{{
				active == 2 ? '完成' : '下一步'
			}}</el-button>
		</div>
	</div>
</template>

<script>
import userImg from '@/components/userImgUpload'
export default {
	name: 'Safety',
	components: {
		userImg,
	},

	data() {
		return {
			token: '',
			IdCardAvatar: '', // 用户头像
			integral: '', // 用户积分
			bonus: '', // 用户奖金
			collectCount: '', // 用户收藏总数
			couponCount: '', // 用户优惠券总数

			Verification: '1', // 定时器显示-隐藏
			Verifications: '1', // 定时器显示-隐藏
			timer: 60,
			timers: 60,
			isPhone: false,
			isCode: false,

			oldPhone: '', // 用户old手机号
			newPhone: '', // 用户new手机号
			oldPhoneCode: '', // old手机验证码
			newPhoneCode: '', // new手机验证码
			newMessage: '请正确输入手机号',

			// 第三方组件属性
			active: 0,
			flag: true, // 是否隐藏组件
			begActualName: '', // 请求参数
			begEmail: '',
			imageUrl: '', // 用户头像
			// 显示/隐藏-更改头像组件
			flagChange: false,
			// 用户信息
			ruleForm: {
				name: '',
				email: '',
				sex: '',
			},
			rules: {
				name: [
					{ required: true, message: '请输入用户昵称', trigger: 'blur' },
					{
						min: 2,
						max: 12,
						message: '长度在 2 到 12 个字符',
						trigger: 'change',
					},
				],
				email: [
					{
						type: 'email',
						required: true,
						message: '请输入正确的用户邮箱',
						trigger: 'blur',
					},
				],
				sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
			},
		}
	},

	mounted() {
		// 默认头像
		this.imageUrl = require('../../../assets/img/person/man.jpg')
		this.userInit()
	},

	methods: {
		// 修改手机号下一步方法
		next() {
			// if (this.active++ >2) this.active = 3
			if (this.active == 0) {
				if (!/^[0-9]{6}$/.test(this.oldPhoneCode)) {
					return (this.isCode = true)
				} else {
					this.isCode = false

					this.api
						.updateUserPhone({
							token: this.token,
							code: this.oldPhoneCode,
							phone: this.oldPhone,
						})
						.then((res) => {
							if (res.code == 0) {
								if (this.active++ > 2) this.active = 2
							}
							// else{
							//     return  this.$message.error(res.msg);
							// }
						})
				}
			} else if (this.active == 1) {
				if (!/^1[3|5|7|8|9]\d{9}$/.test(this.newPhone)) {
					this.isPhone = true
					return
				}
				if (!/^[0-9]{6}$/.test(this.oldPhoneCode)) {
					this.isCode = true
					return
				} else {
					// 修改手机号
					this.api
						.updateUserPhone({
							newPhone: this.newPhone,
							token: this.token,
							newCode: this.newPhoneCode,
						})
						.then((res) => {
							if (
								res != undefined &&
								res.code == 0 &&
								this.newPhoneCode != '' &&
								/^[0-9]{11}$/.test(this.newPhone)
							) {
								if (this.active++ > 2) this.active = 2
							} else {
								return this.$message.error(res.msg)
							}
						})
				}
			} else if (this.active == 2) {
				this.flag = !this.flag
				this.active = 0

				// this.userInit()
				// 更换完成之后-刷新页面
				this.$router.go(0)
			}
		},
		// 跳转修改密码
		toAlterPassword() {
			this.$router.push({
				path: '/login',
				query: {
					type: 2,
				},
			})
		},

		// 获取旧手机验证码
		getCodeBtn1() {
			let phone = this.oldPhone
			if (!/^1[3|5|7|8|9]\d{9}$/.test(phone)) {
				this.oldPhone = ''
				return (this.isPhone = true)
			} else {
				this.isPhone = false
			}
			if (this.newPhone == this.oldPhone) {
				return (this.newMessage = '该手机号已注册')
			}
			this.Verification = 2
			let auth_timer = setInterval(() => {
				//定时器设置每秒递减
				this.timer-- //递减时间
				if (this.timer <= 0) {
					this.timer = 60
					this.Verification = 1 //60s时间结束还原v-show状态并清除定时器
					clearInterval(auth_timer)
				}
			}, 1000)
			this.api.sendCode({ phone: phone }).then((res) => {
				if (res.code == 0) {
					this.$message.success('您的验证码已通过短信发送,请注意查收')
				} else {
					this.Verification = 1
					this.timer = 60
					this.$message.error(res.msg)
				}
			})
		},
		// 获取新手机验证码
		getCodeBtn2() {
			let phone = this.newPhone
			if (!/^1[3|5|7|8|9]\d{9}$/.test(phone)) {
				this.newPhone = ''
				return (this.isPhone = true)
			} else {
				this.isPhone = false
			}
			this.Verifications = 2
			let auth_timers = setInterval(() => {
				//定时器设置每秒递减
				this.timers-- //递减时间
				if (this.timers <= 0) {
					this.timers = 60
					this.Verifications = 1 //60s时间结束还原v-show状态并清除定时器
					clearInterval(auth_timers)
				}
			}, 1000)
			this.api.sendCode({ phone: phone }).then((res) => {
				if (res.code == 0) {
					this.$message.success('您的验证码已通过短信发送,请注意查收')
				} else {
					this.Verifications = 1
					this.timers = 60
					this.$message.error(res.msg)
				}
			})
		},

		// 用户头像
		uploadImage(url) {
			this.imageUrl = url
		},
		// 头像鼠标划入事件
		mouseOver() {
			this.flagChange = true
		},

		// 查询用户信息
		userInit() {
			// 用户token
			this.token = this.$store.state.userData.token
			this.api.findUserInfo({ token: this.token }).then((res) => {
				this.oldPhone = res.data.phone
				// 新用户信息没有头像---显示默认头像
				if (res.data.avatar == '') {
					this.imageUrl = require('../../../assets/img/person/man.jpg')
				} else {
					this.imageUrl = res.data.avatar
				}
				this.integral = res.data.integral
				this.bonus = res.data.bonus
				this.collectCount = res.data.collectCount
				this.couponCount = res.data.couponCount
				//
				if (res.data.sex == 0) {
					this.ruleForm.sex = '男'
				} else if (res.data.sex == 1) {
					this.ruleForm.sex = '女'
				} else {
					this.ruleForm.sex = '保密'
				}
				this.ruleForm.email = res.data.email
				this.ruleForm.name = res.data.nickName
				// 更新vueX数据
				let data = {
					nickname: this.ruleForm.name,
					headImgUrl: this.imageUrl,
					phone: this.oldPhone,
					token: this.token,
					integral: this.integral,
					collectCount: this.collectCount,
					couponCount: this.couponCount,
					bonus: this.bonus,
				}
				this.$store.commit('getUserData', data)
			})
		},
		// 修改事件
		alterPhone() {
			this.flag = !this.flag
		},
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					let token = this.token
					let avatar = this.imageUrl
					let sex

					if (this.$refs[formName].model.sex == '男') {
						sex = 0
					} else if (this.$refs[formName].model.sex == '女') {
						sex = 1
					} else {
						sex = 2
					}

					this.api
						.updateUserInfo({
							token,
							sex,
							avatar: this.imageUrl,
							nickName: this.$refs[formName].model.name,
							email: this.$refs[formName].model.email,
						})
						.then((res) => {
							if (res.code == 0) {
								this.$message({
									message: '成功提交',
									type: 'success',
								})
								this.ruleForm.sex = this.$refs[formName].model.sex
								this.ruleForm.email = this.$refs[formName].model.email
								this.ruleForm.name = this.$refs[formName].model.name
								// 更新vueX数据
								let data = {
									nickname: this.ruleForm.name,
									headImgUrl: this.imageUrl,
									phone: this.oldPhone,
									token: this.token,
									integral: this.integral,
									collectCount: this.collectCount,
									couponCount: this.couponCount,
									bonus: this.bonus,
								}
								this.$store.commit('getUserData', data)
							}
							this.userInit()
						})
				} else {
					return false
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
#safety {
	background: white;
	.safety_title {
		height: 60px;
		line-height: 60px;
		border-bottom: 1px solid #eaeaea;
		span {
			font-size: 16px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #e1251b;
			margin-left: 43px;
		}
	}
	.safety_body {
		display: flex;
		.image {
			width: 118px;
			height: 118px;
			margin-top: 41px;
			margin-left: 62px;
			border-radius: 50%;
			position: relative;
			overflow: hidden;
			img {
				width: 118px;
				height: 118px;
				border-radius: 50%;
			}
			.changeImg {
				position: absolute;
				bottom: 0;
			}
			.none {
				display: none;
			}
		}
		.user_info {
			margin-left: 31px;
			margin-top: 43px;
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #666666;
			p {
				span {
					margin-left: 31px;
				}
			}
			.sex {
				margin-top: 35px;
				span {
					margin-right: 31px;
				}
			}
			.nick {
				margin-top: 35px;
				span {
					margin-right: 31px;
				}
				input {
					width: 272px;
					height: 40px;
					border: 1px solid #cccccc;
					border-radius: 3px;
					padding-left: 10px;
					box-sizing: border-box;
					color: #666666;
				}
			}
			.mail {
				margin-top: 20px;
				span {
					margin-right: 31px;
				}
				input {
					width: 272px;
					height: 40px;
					border: 1px solid #cccccc;
					border-radius: 3px;
					padding-left: 10px;
					box-sizing: border-box;
					color: #666666;
				}
			}
			.btn {
				button {
					width: 134px;
					height: 40px;
					background: #e1251b;
					border-radius: 3px;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ffffff;
					margin-left: 105px;
					margin-top: 27px;
					cursor: pointer;
				}
			}
			::v-deep.el-form {
				margin-left: -13px;
				margin-top: 35px;
				.el-form-item__content {
					left: 20px;
					.el-button--primary {
						width: 134px;
						height: 40px;
						background: #e1251b;
						border-radius: 3px;
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						border-color: #e1251b;
					}
				}
			}
		}
	}
	.safety_footer {
		border-top: 1px solid #eaeaea;
		margin-top: 60px;
		display: flex;
		flex-wrap: wrap;
		.password {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: 30px;
			margin-bottom: 32px;
			margin-left: 75px;
			img {
				width: 40px;
				height: 40px;
				margin-right: 33px;
			}
			.password_title {
				margin-right: 21px;
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #666666;
			}
			.password_text {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
			}
			.password_btn {
				cursor: pointer;
				margin-left: 98px;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #336699;
			}
		}
		.prove {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-left: 75px;
			margin-bottom: 30px;
			img {
				width: 40px;
				height: 40px;
				margin-right: 33px;
			}
			.prove_title {
				margin-right: 21px;
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #666666;
			}
			.prove_text {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
			}
			.prove_btn {
				cursor: pointer;
				margin-left: 100px;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #336699;
			}
		}
	}
	.steps {
		width: 500px;
		padding-top: 70px;
		margin: 0 auto;
		.step_one {
			margin-top: 67px;
			.phone_number {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				span {
					margin-left: 41px;
					color: #333;
				}
			}
			.note {
				margin-top: 43px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				display: flex;
				align-items: center;
				position: relative;
				input {
					margin-left: 37px;
					margin-right: 13px;
					width: 190px;
					height: 40px;
					border: 1px solid #eaeaea;
					border-radius: 2px;
					padding-left: 10px;
				}
				button {
					width: 127px;
					height: 44px;
					background: rgba(0, 0, 0, 0.1);
					border-radius: 1px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
				.sendMsg {
					width: 127px;
					height: 44px;
					background: rgba(0, 0, 0, 0.1);
					border-radius: 5px;
					font-size: 14px;
					color: #333333;
				}
				.item_error {
					color: #f56c6c;
					font-size: 12px;
					line-height: 1;
					position: absolute;
					left: 123px;
					bottom: -20px;
				}
			}
		}
		.step_tow {
			margin-top: 67px;
			.phone_number_new {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				position: relative;
				display: flex;
				display: flex;
				margin-left: -7px;
				align-items: center;
				input {
					margin-left: 37px;
					margin-right: 13px;
					width: 190px;
					height: 40px;
					border: 1px solid #eaeaea;
					border-radius: 2px;
				}
				.label {
					width: 97px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #666666;
					justify-content: flex-end;
				}
				.required {
					color: #db2d39;
					margin-right: 4px;
				}
				.find_form_list_input {
					width: 330px;
					height: 44px;
					border: 1px solid #cecece;
					border-radius: 3px;
					margin-left: 40px;
					font-size: 14px;
					padding-left: 10px;
					box-sizing: border-box;
				}
				.item_error {
					color: #f56c6c;
					font-size: 12px;
					line-height: 1;
					position: absolute;
					left: 135px;
					bottom: -20px;
				}
			}
			.note {
				margin-top: 43px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				display: flex;
				align-items: center;
				position: relative;
				.item_error {
					color: #f56c6c;
					font-size: 12px;
					line-height: 1;
					position: absolute;
					left: 135px;
					bottom: -20px;
				}
				input {
					margin-left: 37px;
					margin-right: 13px;
					width: 190px;
					height: 40px;
					border: 1px solid #eaeaea;
					border-radius: 2px;
					padding-left: 10px;
				}
				button {
					width: 127px;
					height: 44px;
					background: rgba(0, 0, 0, 0.1);
					border-radius: 1px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
				.sendMsg {
					width: 127px;
					height: 44px;
					background: rgba(0, 0, 0, 0.1);
					border-radius: 5px;
					font-size: 14px;
					color: #333333;
				}
			}
		}
		.step_three {
			text-align: center;
			p {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				margin-top: 64px;
			}
		}
		::v-deep .el-button {
			width: 325px;
			height: 44px;
			background: #db2d39;
			border-radius: 3px;
			margin-left: 123px;
			margin-bottom: 170px;
			span {
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #ffffff;
			}
			::v-deep .el-button:active {
				border: none;
			}
		}
	}
}
</style>
<style scoped>
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}
.avatar {
	width: 178px;
	height: 178px;
	display: block;
}
</style>