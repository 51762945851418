<template>
	<!-- 邀请好友组件 -->
	<div id="friend">
		<div class="headline">
			<span class="headline_part">邀请好友</span>
		</div>
		<div class="friend_header">
			<div class="left">
				<!-- <img src="../../../assets/img/person/friendImg.png" alt=""> -->
				<div id="qrcode" class="qrcode qrCode_img" ref="qrCodeDiv"></div>
				<div class="left_detail">
					<p
						class="detailBtn"
						@click="copyBtn"
						ref="clipboard_pc"
						:data-clipboard-text="'www.baidu.com'"
					>
						复制/保存二维码
					</p>
					<p class="detailText">分享您的专属二维码至好友或朋友圈，</p>
					<p class="detailText">通过您的二维码扫描注册，即可获得邀请奖励</p>
				</div>
			</div>
			<div class="right">
				<p class="rightNum">{{ inviteNum }}</p>
				<p class="rightText">邀请总人数(个)</p>
			</div>
		</div>
		<div class="friedTitle">
			<span class="friedTitle_details">用户昵称</span
			><span class="friedTitle_type">用户类型</span
			><span class="friedTitle_act">注册时间</span>
		</div>
		<div class="details_info" v-for="(item, index) in dataList" :key="index">
			<div class="infoTitle">{{ item.phone }}</div>
			<div class="bill_type">{{ item.userType == '00' ? '个人' : '企业' }}</div>
			<div class="detail_info_open">{{ item.registrationTime }}</div>
		</div>

		<div class="pages" v-if="total != 0">
			<Pages :total="total" :params="params" @jump="pageSkip"></Pages>
		</div>
	</div>
</template>

<script>
import Pages from '@/components/pages.vue'
import QRCode from 'qrcodejs2'
import clipboard from '@/http/clipboard'
export default {
	name: 'Friend',
	components: {
		Pages,
	},
	data() {
		return {
			// 邀请个数
			inviteNum: 0,
			// 分页-总条数
			total: 0,
			// 当前页-多少条
			params: {
				page: 1,
				limit: 10,
				token: this.$store.state.userData.token,
			},
			dataList: [],
		}
	},

	mounted() {
		let url =
			'https://www.golkia-mall.com/share/#/?id=' + this.$store.state.userData.id
		this.$nextTick(function () {
			this.createQRCode(url)
		})
		this.init()
	},

	methods: {
		init() {
			this.api.findPageUserInviteRecord(this.params).then((res) => {
				if (res.code == 0) {
					(this.dataList = res.data), (this.inviteNum = res.count)
					this.total = res.count
				}
			})
		},
		createQRCode(url) {
			new QRCode(this.$refs.qrCodeDiv, {
				text: url,
				width: 180,
				height: 180,
				colorDark: '#000000',
				colorLight: '#ffffff',
				correctLevel: QRCode.CorrectLevel.M, //容错率，L/M/H
			})
		},
		copyBtn(event) {
			let container = document.getElementById('qrcode').childNodes[1]
			let myBlob = this.base64ToBlob(container.getAttribute('src'))
			navigator.clipboard.write([
				new window.ClipboardItem({ [myBlob.type]: myBlob }),
			])
			this.$message.success('复制成功')
			// clipboard(
			// 	'https://www.golkia-mall.com/share/#/?id=' +
			// 		this.$store.state.userData.id,
			// 	event
			// )
		},
		base64ToBlob(dataurl) {
			var arr = dataurl.split(','),
				mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[1]),
				n = bstr.length,
				u8arr = new Uint8Array(n)
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n)
			}
			return new Blob([u8arr], { type: mime })
		},
		//分页
		pageSkip(page) {
			this.params = page
			this.init()
		},
	},
}
</script>

<style lang="scss" scoped>
#friend {
	height: 880px;
	background-color: white;
	position: relative;
	.pages {
		width: 100%;
		position: absolute;
		bottom: 10px;
	}
	.headline {
		height: 60px;
		border-bottom: 1px solid #ededed;
		margin-bottom: 17px;
		.headline_part {
			margin-left: 41px;
			line-height: 60px;
			font-size: 17px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #e1251b;
		}
	}
	.friend_header {
		width: 897px;
		height: 228px;
		background: #f4f4f4;
		margin: 0 auto;
		margin-bottom: 30px;
		display: flex;
		align-items: center;
		.left {
			flex: 2;
			display: flex;
			img {
				width: 186px;
				height: 186px;
				flex: 1;
				margin-left: 20px;
			}
			.qrCode_img {
				flex: 1;
				margin-left: 20px;
				width: 186px;
				height: 186px;
			}
			.left_detail {
				flex: 2;
				border-right: 1px solid rgba(51, 51, 51, 0.2);
				text-align: center;
				.detailBtn {
					cursor: pointer;
					display: inline-block;
					width: 210px;
					height: 37px;
					line-height: 37px;
					background: #e62129;
					border-radius: 19px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ffffff;
					margin-top: 41px;
					margin-bottom: 24px;
				}
				.detailText {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 24px;
				}
			}
		}
		.right {
			flex: 1;
			text-align: center;
			.rightNum {
				font-size: 36px;
				font-family: Arial;
				font-weight: 400;
				color: #e1251b;
				line-height: 30px;
			}
			.rightText {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				line-height: 30px;
			}
		}
	}
	.friedTitle {
		background-color: #f4f4f4;
		width: 897px;
		height: 42px;
		margin: 0 auto;
		margin-bottom: 11px;
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		display: flex;
		.friedTitle_details {
			flex: 1;
			text-align: center;
			line-height: 42px;
		}
		.friedTitle_type {
			flex: 1;
			text-align: center;
			line-height: 42px;
		}
		.friedTitle_act {
			flex: 1;
			text-align: center;
			line-height: 42px;
		}
	}
	.details_info {
		width: 897px;
		height: 45px;
		line-height: 45px;
		margin: 0 auto;
		border-bottom: 1px solid #eaeaea;
		font-size: 12px;
		font-family: Microsoft YaHei;
		color: #666666;
		display: flex;
		.infoTitle {
			flex: 1;
			text-align: center;
		}
		.bill_type {
			flex: 1;
			text-align: center;
		}
		.detail_info_open {
			flex: 1;
			text-align: center;
		}
	}
}
</style>