<template>
	<!-- 我的足迹组件 -->
	<div id="collect">
		<div class="headline">
			<span class="headline_track" @click="collectToTrack(8)">收藏的商品</span
			><span class="headline_collect">我的足迹</span>
		</div>
		<div class="hint" v-if="!showList">用户暂无足迹~~</div>
		<div class="showTrack">
			<div
				class="tasks"
				v-for="item in showList"
				:key="item.id"
				@click="toGoodsDetail(item.goodsListVO.id)"
			>
				<img :src="item.goodsListVO.coverUrl" alt="" />
				<p class="taskTitle" v-html="item.goodsListVO.productName"></p>
				<p class="money">
					￥<span class="money_number">{{
						Number(item.goodsListVO.price).toFixed(2)
					}}</span>
				</p>
			</div>
		</div>

		<!-- 分页 -->
		<!-- <div class="block">
          <el-pagination
            @size-change="handleSizeChangeCollect"
            @current-change="handleCurrentChangeCollect"
            :page-size="8"
            layout="prev, pager, next, jumper"
            :total="totalCollect">
          </el-pagination>
        </div> -->
	</div>
</template>

<script>
export default {
	name: 'Collect',
	data() {
		return {
			// 足迹列表
			showList: [],
			// 分页
			// totalCollect:40,
		}
	},
	mounted() {
		this.collectInit()
	},
	methods: {
		// 查询用户足迹
		collectInit() {
			this.api
				.findByUserIdAndProductIdLists({
					token: this.$store.state.userData.token,
				})
				.then((res) => {
					console.log(res)
					let data = res.data
					data.forEach((element, index) => {
						// 后台删除该产品信息-足迹还在
						if (element.goodsListVO == null) {
							data.splice(index, 1)
						}
					})

					this.showList = res.data
				})
		},
		collectToTrack(dat) {
			// 向父组件传值
			this.$emit('changeAtt', dat)
		},
		// 进入商品详情页
		toGoodsDetail(id) {
			let routeData = this.$router.resolve({
				path: '/mall/goodsDetails',
				query: {
					id: id,
				},
			})
			window.open(routeData.href, '_blank')
		},

		// 分页
		// handleSizeChangeCollect(val) {
		//     console.log(`每页 ${val} 条`);
		// },
		// handleCurrentChangeCollect(val) {
		//     console.log(`当前页: ${val}`);
		// },
	},
}
</script>

<style lang="scss" scoped>
#collect {
	width: 980px;
	min-height: 880px;
	background-color: white;
	position: relative;
	float: left;
	.headline {
		height: 60px;
		border-bottom: 1px solid #ededed;
		.headline_track {
			margin-left: 31px;
			margin-right: 20px;
			line-height: 60px;
			font-size: 16px;
			font-family: Microsoft YaHei;
			cursor: pointer;
		}
		.headline_collect {
			line-height: 56px;
			font-size: 16px;
			font-family: Microsoft YaHei;
			color: #e62129;
			display: inline-block;
			border-bottom: 2px solid red;
		}
	}
	.showTrack {
		float: left;
		.tasks {
			width: 220px;
			height: 294px;
			border: 1px solid #eaeaea;
			margin-top: 15px;
			margin-bottom: 15px;
			float: left;
			margin-left: 12px;
			margin-right: 12px;
			cursor: pointer;
			img {
				width: 168px;
				height: 168px;
				margin-left: 24px;
				margin-top: 25px;
			}
			.taskTitle {
				margin-top: 18px;
				margin-left: 22px;
				width: 170px;
				height: 33px;
				font-size: 12px;
				color: #333333;
				// 溢出省略号
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
			.money {
				width: 74px;
				height: 15px;
				font-size: 18px;
				font-weight: bold;
				color: #e1251b;
				margin-left: 28px;
				margin-top: 16px;
			}
		}
	}
	.block {
		margin-top: 20px;
		text-align: center;
		.el-pagination {
			display: inline-block;
		}
	}
	.hint {
		color: #909399;
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -10px;
		margin-left: -60px;
	}
}
</style>