<template>
	<!-- 认证信息组件 -->
	<div id="Approve">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="个人认证" name="second">
				<div
					v-if="approveText.auditState"
					:class="approveText.auditState == 2 ? 'hint firm' : 'hint people'"
				>
					<img
						v-if="approveText.auditState == 1 ? false : true"
						:src="
							approveText.auditState == 1
								? approveImgNo
								: approveText.auditState == 2
								? approveImgYes
								: approveImgNo
						"
						alt=""
					/>
					<p>
						{{
							approveText.auditState == 1
								? '审核认证中~~~'
								: approveText.auditState == 2
								? '恭喜，您提交的认证审核已通过。'
								: '很遗憾，您的认证审核未通过。理由：' +
								  approveText.notReason +
								  '，请重新提交！'
						}}
					</p>
				</div>
				<!--  v-if="approveText.auditState == 2?false:true" -->
				<div
					class="hintForm"
					v-if="
						approveText.auditState == 2 || approveText.type == 2 ? false : true
					"
				>
					<el-form
						ref="form"
						:model="form"
						label-position="left"
						label-width="80px"
					>
						<el-form-item label="真实姓名:">
							<el-input
								placeholder="请输入您身份证上的姓名"
								v-model="form.name"
							></el-input>
						</el-form-item>
						<!-- <el-form-item label="性别:">
                            <el-radio-group v-model="form.sex">
                                <el-radio label="男"></el-radio>
                                <el-radio label="女"></el-radio>
                            </el-radio-group>
                        </el-form-item> -->
						<el-form-item label="手机号码:">
							<el-input
								placeholder="请输入手机号码"
								v-model="form.phone"
							></el-input>
						</el-form-item>
						<el-form-item label="身份证:">
							<div class="just">
								<!-- 正面 -->
								<img :src="imageUrlFront" alt="" />
								<uploadJust @uploadImageJust="uploadImageJust" />
							</div>
							<div class="unjust">
								<!-- 反面 -->
								<img :src="imageUrl" alt="" />
								<uploadUnjust @uploadImageUnjust="uploadImageUnjust" />
							</div>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="onSubmit('form')">{{
								approveType == 1 ? '提交认证' : '重新提交'
							}}</el-button>
						</el-form-item>
					</el-form>
				</div>
			</el-tab-pane>
			<el-tab-pane label="企业认证" name="first">
				<div
					v-if="approveText.auditState"
					:class="approveText.auditState == 2 ? 'hint firm' : 'hint people'"
				>
					<img
						v-if="approveText.auditState == 1 ? false : true"
						:src="
							approveText.auditState == 1
								? approveImgNo
								: approveText.auditState == 2
								? approveImgYes
								: approveImgNo
						"
						alt=""
					/>
					<p>
						{{
							approveText.auditState == 1
								? '审核认证中~~~'
								: approveText.auditState == 2
								? '恭喜，您提交的认证审核已通过。'
								: '很遗憾，您的认证审核未通过。理由：' +
								  approveText.notReason +
								  '，请重新提交！'
						}}
					</p>
				</div>
				<!-- v-if="approveText.auditState == 2?false:true" -->
				<div
					class="hintForm"
					v-if="
						approveText.auditState == 2 || approveText.type == 1 ? false : true
					"
				>
					<el-form
						ref="form"
						:model="form"
						label-position="left"
						label-width="80px"
					>
						<el-form-item label="公司名称:">
							<el-input
								placeholder="请输入公司名称"
								v-model="form.firmName"
							></el-input>
						</el-form-item>
						<el-form-item label="联系人:">
							<el-input
								placeholder="请输入联系人姓名"
								v-model="form.firmPeople"
							></el-input>
						</el-form-item>
						<el-form-item label="联系号码:">
							<el-input
								placeholder="请输入联系人电话号码"
								v-model="form.peoplePhone"
							></el-input>
						</el-form-item>
						<el-form-item label="营业执照:">
							<div class="firmImg">
								<img :src="imageUrlFirm" alt="" />
								<uploadFirm @uploadImageFirm="uploadImageFirm" />
							</div>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="onSubmitFirm('form')">{{
								approveType == 1 ? '提交认证' : '重新提交'
							}}</el-button>
						</el-form-item>
					</el-form>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import uploadJust from '@/components/IDCardJust.vue'
import uploadUnjust from '@/components/IDCardUnjust.vue'
import uploadFirm from '@/components/IDCardFirm.vue'
export default {
	name: 'Approve',
	components: {
		uploadJust,
		uploadUnjust,
		uploadFirm,
	},
	props: {
		activeTab: String,
	},
	data() {
		return {
			activeName: 'second',
			// 认证状态信息
			approveText: {
				auditState: '', //认证状态
				notReason: '', //审核不通过理由
				type: '', //认证类型
			},
			// 新增-修改(1新增2修改)
			approveType: 1,
			// 审核认证状态信息
			approveInfo: {},
			// 认证状态图片
			approveImgYes: '',
			approveImgNo: '',
			// 组件属性
			form: {
				name: '', //真实姓名
				phone: '', //手机号
				// sex: '', //真实姓名性别
				//
				firmName: '', //企业名称
				firmPeople: '', //联系人
				peoplePhone: '', //联系号码
			},
			// 身份证正面
			imageUrlFront: '',
			// 身份证反面
			imageUrl: '',
			// 营业执照
			imageUrlFirm: '',
		}
	},

	mounted() {
		this.imageUrlFront = require('../../../assets/img/person/IDcard_just.png')
		this.imageUrl = require('../../../assets/img/person/IDcard_unjust.png')
		this.imageUrlFirm = require('../../../assets/img/person/firm.png')
		this.approveImgYes = require('../../../assets/img/person/yitongguo.png')
		this.approveImgNo = require('../../../assets/img/person/weitongguo.png')
		this.approveInit()
		this.activeName = this.activeTab
	},

	methods: {
		// 身份证正面地址
		uploadImageJust(url) {
			this.imageUrlFront = url
		},
		// 身份证反面地址
		uploadImageUnjust(url) {
			this.imageUrl = url
		},
		// 企业营业执照
		uploadImageFirm(url) {
			this.imageUrlFirm = url
		},
		//个人认证
		onSubmit(Form) {
			// let data = this.$refs[Form].model
			let data = this.form
			let token = this.$store.state.userData.token
			if (!this.imageUrlFront.includes('oss-cn-shenzhen')) {
				this.$message({
					showClose: true,
					message: '请上传身份证正面照片',
					type: 'warning',
				})
				return false
			}
			if (!this.imageUrl.includes('oss-cn-shenzhen')) {
				this.$message({
					showClose: true,
					message: '请上传身份证反面照片',
					type: 'warning',
				})
				return false
			}

			if (this.approveType == 1) {
				this.api
					.insertCertification({
						nickName: data.name,
						type: '1',
						phone: data.phone,
						certificateFront: this.imageUrlFront,
						certificateBack: this.imageUrl,
						token,
					})
					.then((res) => {
						// 提交成功-清空信息
						this.form = {
							// 个人认证
							name: '',
							phone: '',
							// sex: '',
							// 企业认证
							firmName: '',
							firmPeople: '',
							peopleNum: '',
						}
						if (res.code == 0) {
							this.$message({
								message: '认证提交成功，待审核中',
								type: 'success',
							})
						}
						this.imageUrlFront = require('../../../assets/img/person/IDcard_just.png')
						this.imageUrl = require('../../../assets/img/person/IDcard_unjust.png')
					})
				this.approveType = 2
			} else {
				this.api
					.updateCertificationState({
						nickName: data.name,
						phone: data.phone,
						certificateFront: this.imageUrlFront,
						certificateBack: this.imageUrl,
						token: this.$store.state.userData.token,
						id: this.approveInfo.id,
						auditState: this.approveInfo.auditState,
						type: this.approveInfo.type,
					})
					.then((res) => {
						if (res.code == 0) {
							this.$message({
								message: '认证提交成功，待审核中',
								type: 'success',
							})
							this.approveInit()
						}
						// else{
						//     this.$message({
						//         message: res.msg,
						//         type: 'warning'
						//     });
						// }
					})
			}
		},
		//企业认证
		onSubmitFirm(Form) {
			// let data = this.$refs[Form].model
			let data = this.form
			let token = this.$store.state.userData.token

			if (!this.imageUrlFirm.includes('oss-cn-shenzhen')) {
				this.$message({
					showClose: true,
					message: '请上传企业营业执照照片',
					type: 'warning',
				})
				return false
			}
			if (this.approveType == 1) {
				this.api
					.insertCertification({
						nickName: data.firmName,
						type: '2',
						phone: data.peoplePhone,
						certificateFront: this.imageUrlFirm,
						token,
						contacts: data.firmPeople,
					})
					.then((res) => {
						// 提交成功-清空信息
						this.form = {
							// 个人认证
							name: '',
							phone: '',
							// sex: '',
							// 企业认证
							firmName: '',
							firmPeople: '',
							peopleNum: '',
						}
						if (res.code == 0) {
							this.$message({
								message: '认证提交成功，待审核中',
								type: 'success',
							})
							this.approveInit()
						}
						this.imageUrlFront = require('../../../assets/img/person/IDcard_just.png')
						this.imageUrlFirm = require('../../../assets/img/person/firm.png')
						this.imageUrl = require('../../../assets/img/person/IDcard_unjust.png')
					})
				this.approveType = 2
			} else {
				this.api
					.updateCertificationState({
						nickName: data.firmName,
						type: '2',
						phone: data.peoplePhone,
						certificateFront: this.imageUrlFirm,
						token: this.$store.state.userData.token,
						contacts: data.firmPeople,
						id: this.approveInfo.id,
						auditState: this.approveInfo.auditState,
						type: this.approveInfo.type,
					})
					.then((res) => {
						// 提交成功-清空信息
						this.form = {
							// 个人认证
							name: '',
							phone: '',
							// sex: '',
							// 企业认证
							firmName: '',
							firmPeople: '',
							peopleNum: '',
						}
						if (res.code == 0) {
							this.$message({
								message: '认证提交成功，待审核中',
								type: 'success',
							})
						}
						this.imageUrlFront = require('../../../assets/img/person/IDcard_just.png')
						this.imageUrlFirm = require('../../../assets/img/person/firm.png')
						this.imageUrl = require('../../../assets/img/person/IDcard_unjust.png')
					})
				this.approveType = 2
			}
		},

		// 查询认证状态
		approveInit() {
			this.api
				.findCertificationState({ token: this.$store.state.userData.token })
				.then((res) => {
					if (res.data != null) {
						let data = res.data
						switch (data.auditState) {
							case 2:
								this.approveText.auditState = data.auditState
								this.approveText.notReason = data.notReason
								break
							case 1:
								this.approveText.auditState = data.auditState
								this.approveText.notReason = data.notReason
								break
							case 3:
								this.approveText.auditState = data.auditState
								this.approveText.notReason = data.notReason
								break
							default:
								break
						}
						this.approveInfo = res.data
						this.approveType = 2
						this.approveText.type = data.type
						if (data.auditState != 2 && data.type == 2) {
							// 企业认证(待审核,不通过)
							this.form.firmName = data.nickName
							this.form.firmPeople = data.contacts
							this.form.peoplePhone = data.phone
							this.imageUrlFirm = data.certificateFront
						} else if (data.auditState != 2 && data.type == 1) {
							// 个人认证(待审核,不通过)
							this.form.name = data.nickName
							this.form.phone = data.phone
							this.imageUrlFront = data.certificateFront
							this.imageUrl = data.certificateBack
						}
					} else {
						// 将认证状态存储在vueX
						let userText = JSON.parse(localStorage.getItem('userData'))
						userText.approveType = ''

						this.$store.commit('getUserData', userText)
					}
				})
		},

		// tab切换事件
		handleClick(tab, event) {},
	},
}
</script>

<style lang="scss" scoped>
#Approve {
	width: 980px;
	height: 880px;
	background-color: white;
	::v-deep .el-tabs {
		.el-tabs__header {
			height: 60px;
			margin: 0;
			.el-tabs__nav-wrap {
				height: 60px;
				.el-tabs__nav-scroll {
					height: 60px;
					.el-tabs__nav {
						height: 60px;
						line-height: 60px;
						margin-left: 52px;
						.el-tabs__active-bar {
							background: #e62129;
						}
						.is-active {
							color: #e1251b;
						}
						.el-tabs__item:hover {
							color: #e1251b;
						}
						.el-tabs__item {
							font-size: 16px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}
					}
				}
			}
		}
		.el-tabs__content {
			.el-tab-pane {
				// 企业认证信息提示样式
				.people {
					background: #fff5e6;
					p {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #e1251b;
					}
				}
				// 个人认证信息提示样式
				.firm {
					background: #e5ffda;
					p {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #27b900;
					}
				}
				.hint {
					width: 980px;
					height: 56px;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					img {
						width: 36px;
						height: 31px;
						margin-right: 14px;
					}
				}
				.hintForm {
					margin-top: 95px;
					.el-form {
						width: 600px;
						margin: 0 auto;
						.el-form-item {
							.el-form-item__content {
								display: flex;
								.just {
									margin-right: 22px;
									img {
										width: 247px;
										height: 141px;
									}
									.upLoadBox {
										width: 70px;
										height: 70px;
										top: -105px;
										left: 80px;
										.imgBox {
											width: 112px;
											height: 70px;
											display: flex;
											align-items: center;
											justify-content: center;
											flex-wrap: wrap;
											img {
												width: 28px;
												height: 28px;
											}
										}
										.imgBox::after {
											content: '上传身份证人像面';
											font-size: 14px;
											color: #666666;
										}
										.uploadBtn {
											.el-upload {
												width: 247px;
												height: 140px;
												top: -36px;
												left: -80px;
											}
										}
									}
								}
								.unjust {
									img {
										width: 247px;
										height: 141px;
									}
									.upLoadBox {
										width: 70px;
										height: 70px;
										top: -105px;
										left: 80px;
										.imgBox {
											width: 112px;
											height: 70px;
											display: flex;
											align-items: center;
											justify-content: center;
											flex-wrap: wrap;
											img {
												width: 28px;
												height: 28px;
											}
										}
										.imgBox::after {
											content: '上传身份证国徽面';
											font-size: 14px;
											color: #666666;
										}
										.uploadBtn {
											.el-upload {
												width: 247px;
												height: 140px;
												top: -36px;
												left: -80px;
											}
										}
									}
								}
								.firmImg {
									width: 247px;
									height: 141px;
									border: 1px dashed #ccc;
									img {
										width: 245px;
										height: 139px;
									}
									.upLoadBox {
										width: 70px;
										height: 70px;
										top: -105px;
										left: 90px;
										.imgBox {
											width: 70px;
											height: 70px;
											display: flex;
											align-items: center;
											justify-content: center;
											flex-wrap: wrap;
											img {
												width: 28px;
												height: 28px;
											}
										}
										.uploadBtn {
											.el-upload {
												width: 247px;
												height: 140px;
												top: -36px;
												left: -80px;
											}
										}
									}
								}

								.el-input {
									width: 274px;
									height: 40px;
									background: #ffffff;
									border-radius: 3px;
								}
								.el-radio-group {
									margin-top: 13px;
									.el-radio {
										.el-radio__inner:hover {
											border-color: #e1251b;
										}
										.el-radio__label {
											color: #666;
										}
										.is-checked {
											.el-radio__inner {
												border-color: #e1251b;
												background: #e1251b;
											}
										}
									}
								}
								.el-button--primary {
									margin-left: 20px;
									width: 134px;
									height: 40px;
									background: #e1251b;
									border-radius: 3px;
									border: none;
									span {
										font-size: 18px;
										font-family: Microsoft YaHei;
										font-weight: 400;
										color: #ffffff;
									}
								}
							}
							.el-form-item__label {
								margin-right: 20px;
							}
						}
					}
				}
			}
		}
	}
}
</style>