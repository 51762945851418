<template>
	<!-- 我的积分组件 -->
	<div id="Part">
		<div class="headline">
			<span class="headline_part">我的积分</span>
		</div>
		<div class="validPart">
			<p>
				可用积分：<span>{{ partNum }}</span>
			</p>
		</div>
		<div class="title">
			<el-table
				ref="filterTable"
				:cell-style="rowClass"
				:data="tableData"
				style="width: 100%"
			>
				<el-dropdown @command="handleCommand">
					<span class="el-dropdown-link">
						<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item
							v-if="text == '近三个月' ? false : true"
							command="近三个月"
							>近三个月</el-dropdown-item
						>
						<el-dropdown-item
							v-if="text == '今年内' ? false : true"
							command="今年内"
							>今年内</el-dropdown-item
						>
						<el-dropdown-item
							v-if="text == '全部' ? false : true"
							command="全部"
							>全部</el-dropdown-item
						>
					</el-dropdown-menu>
				</el-dropdown>
				<el-table-column
					align="center"
					prop="createTime"
					:label="text"
					width="224"
					column-key="date"
				></el-table-column>
				<el-table-column
					align="center"
					prop="price"
					label="收入/支出"
					width="224"
				></el-table-column>
				<el-table-column
					align="center"
					prop="surplusMoney"
					label="收入/支出可用积分"
					width="224"
				></el-table-column>
				<el-table-column
					align="center"
					prop="orderCode"
					label="详细说明"
					width="225"
				></el-table-column>
			</el-table>
		</div>
		<!-- 分页 -->
		<div class="pageBox" v-if="total >= 10">
			<Pages :total="total" :params="params" @jump="pageSkip"></Pages>
		</div>
	</div>
</template>

<script>
import Pages from '@/components/pages.vue'
export default {
	name: 'Part',
	components: {
		Pages,
	},
	data() {
		return {
			partNum: '0',
			text: '全部',
			// 积分列表
			tableData: [],
			// 分页
			total: 0,
			params: {
				token: this.$store.state.userData.token,
				page: 1,
				limit: 10,
				getTime: '',
			},
		}
	},
	mounted() {
		this.partInit()
		this.api
			.findUserInfo({ token: this.$store.state.userData.token })
			.then((res) => {
				this.partNum = res.data.integral
			})
	},
	methods: {
		partInit() {
			// 查询积分列表

			this.api.billSelectByUserId(this.params).then((res) => {
				if (res.code == 0) {
					let data = JSON.parse(JSON.stringify(res.data))
					data.forEach((item) => {
						switch (item.type) {
							case 1:
								item.orderCode = item.orderCode + '(注册获得)'
								break
							case 2:
								item.orderCode = item.orderCode + '(下线返利)'
								break
							case 3:
								item.orderCode = item.orderCode + '(购物消费)'
								break
							case 4:
								item.orderCode = item.orderCode + '(申请退货)'
								break
							case 5:
								item.orderCode = item.orderCode + '(押金)'
								break
							case 6:
								item.orderCode = item.orderCode + '(邀请)'
								break
							case 7:
								item.orderCode = item.orderCode + '(产品被购买)'
								break
							case 8:
								item.orderCode = item.orderCode + '(VIP升级)'
								break
							default:
								break
						}
						switch (item.status) {
							case 0:
								item.price = '+' + item.price
								item.class = 'color:red;'
								break
							case 1:
								item.price = '-' + item.price
								break
							default:
								break
						}
					})
					this.tableData = data
					this.total = res.count
				}
			})
		},

		// 改变'收入/支出'积分颜色
		rowClass(row) {
			if (row.column.label === '收入/支出' && row.row.status == 0) {
				return 'color:red;'
			}
		},
		//分页
		pageSkip(page) {
			this.params = page
			this.partInit()
		},
		// 下拉菜单-赛选数据
		handleCommand(command) {
			this.text = command
			if (command == '近三个月') {
				this.params.getTime = 1
				this.partInit()
			} else if (command == '今年内') {
				this.params.getTime = 2
				this.partInit()
			} else {
				this.params.getTime = ''
				this.partInit()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
#Part {
	min-height: 880px;
	background-color: white;
	.headline {
		height: 60px;
		border-bottom: 1px solid #ededed;
		margin-bottom: 17px;
		.headline_part {
			margin-left: 41px;
			line-height: 60px;
			font-size: 17px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #e1251b;
		}
	}
	.validPart {
		margin: 0 auto;
		width: 897px;
		height: 96px;
		background: #f4f4f4;
		line-height: 96px;
		margin-bottom: 22px;
		p {
			margin-left: 41px;
			font-size: 20px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #666666;
		}
		span {
			margin-left: 10px;
			font-size: 36px;
			line-height: 30px;
			font-family: Arial;
			font-weight: 400;
			color: #e1251b;
		}
	}
	.title {
		width: 897px;
		margin: 0 auto;
		text-align: center;
		::v-deep .el-table {
			.el-table__header-wrapper {
				.el-table__header {
					.has-gutter {
						tr {
							.is-leaf {
								background-color: #eaeaea !important;
							}
						}
					}
				}
			}
			.hidden-columns {
				z-index: 99999;
				top: 14px;
				left: 150px;
				visibility: visible;
			}
		}
	}
}
</style>