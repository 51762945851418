<template>
	<div class="upLoadBox">
		<div class="imgBox">
			<img src="@/assets/img/addimg.png" alt />
		</div>
		<el-upload
			action
			limit:1
			:show-file-list="false"
			:before-upload="beforeAvatarUpload"
			:http-request="uploadImage"
			class="uploadBtn"
		>
		</el-upload>
	</div>
</template>
<script>
const OSS = require('ali-oss')
export default {
	props: {},
	data() {
		return {
			url: '',
			client: {},
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		beforeAvatarUpload(file) {
			// 图片格式
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
			// 图片大小
			const isLt2M = file.size / 1024 / 1024 < 2.5
			if (!isJPG) {
				this.$message.error('上传图片只能为jpg或png格式')
			}
			if (!isLt2M) {
				this.$message.error('上传图片大小不能超过2MB')
			}

			return isJPG && isLt2M
		},

		GetTimeString() {
			var date = new Date()
			var yy = date.getFullYear().toString()
			var mm = (date.getMonth() + 1).toString()
			var dd = date.getDate().toString()
			var hh = date.getHours().toString()
			var nn = date.getMinutes().toString()
			var ss = date.getSeconds().toString()
			var mi = date.getMilliseconds().toString()
			var ret = yy + mm + dd + hh + nn + ss + mi
			var name = yy + mm + dd
			return '/' + name + '/' + ret
		},
		init() {
			this.api.getOssTemporaryCredential().then((res) => {
				this.client = new OSS({
					// yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
					region: 'oss-cn-shenzhen',
					// 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
					accessKeyId: res.data.accessKeyId,
					accessKeySecret: res.data.accessKeySecret,
					// 从STS服务获取的安全令牌（SecurityToken）。
					stsToken: res.data.securityToken,
					//填写Bucket名称。
					bucket: 'golkia',
				})
			})
		},
		async uploadImage(file) {
			const headers = {
				'x-oss-storage-class': 'Standard',
				'content-type': 'image/jpg',
			}
			var data = file.file
			let fileName = data.name
				? data.name.substring(data.name.lastIndexOf('.')).toLowerCase()
				: ''
			let ret = this.GetTimeString() + fileName
			try {
				// 填写Object完整路径。Object完整路径中不能包含Bucket名称。
				// 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
				// data对象可以自定义为file对象、Blob数据或者OSS Buffer。
				if (this.client) {
					const result = await this.client.put(ret, data, { headers })
					this.$emit('uploadImageJust', result.url)
				}
			} catch (e) {}
		},
	},
}
</script>
<style lang="scss" scoped>
.upLoadBox {
	background: rgb(255, 255, 255, 0);
	border-radius: 6px;
	position: relative;
	img {
		width: 28px;
		height: 28px;
	}
	.imgBox {
		width: 70px;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	::v-deep.el-upload {
		width: 70px;
		height: 70px;
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 99;
	}
}
</style>
