<template>
	<div class="order_box">
		<div class="order_list" v-for="(item, index) in goodsData" :key="index">
			<div class="head Between">
				<div class="head_time_orderCode flex-center">
					<div class="order_time">{{ item.submitTime }}</div>
					<div class="order_code">订单号：{{ item.orderCode }}</div>
				</div>
				<div class="head_fl pointer flex">
					<div class="order_status" v-if="item.tradeStatus == 1">
						等待买家付款
					</div>
					<div class="order_status" v-if="item.tradeStatus == 2">
						买家已付款
					</div>
					<div class="order_status pointer" v-if="item.tradeStatus == 3">
						物流运输中
					</div>
					<div class="order_status pointer" v-if="item.tradeStatus == 4">
						交易成功
					</div>
					<div class="order_status pointer" v-if="item.tradeStatus == 5">
						已完成
					</div>
					<div class="order_status" v-if="item.tradeStatus == 6">交易关闭</div>
					<div class="order_status pointer" v-if="item.tradeStatus == 7">
						售后中
					</div>
					<div class="order_status pointer" v-if="item.tradeStatus == 8">
						申请通过
					</div>
					<div class="order_status pointer" v-if="item.tradeStatus == 9">
						申请不通过
					</div>
					<img
						@click="delBtn(item.id)"
						v-if="item.tradeStatus == 5 || item.tradeStatus == 6"
						src="../img/orderDel.png"
						alt=""
					/>
				</div>
			</div>
			<div class="flex">
				<div class="list">
					<div
						class="goods_list_box flex"
						v-for="(items, indexs) in item.orderItemVOS"
						:key="indexs"
					>
						<div class="goods_list">
							<div
								class="list flex-center pointer"
								@click="toGoods(items.goodsId)"
								:class="item.orderItemVOS.length > 1 ? 'lists' : ''"
							>
								<div class="goodsImg">
									<img :src="items.goodsUrl" alt="" />
								</div>
								<div class="goods_name_specification">
									<div class="goods_name astrict">{{ items.goodsName }}</div>
									<div class="goods_specification astrict">
										{{ items.skuTitle }}
									</div>
								</div>
								<div class="goodsPrice">
									￥{{ Number(items.unitPrice).toFixed(2) }}
								</div>
								<div class="goodsNum">x{{ items.goodsNum }}</div>
							</div>
							<div class="goodsDelFlag" v-if="items.goodsDelFlag == 2"></div>
						</div>
						<div class="totalPrice flex-center column">
							<div class="price">
								￥{{ Number(items.totalPrice).toFixed(2) }}
							</div>
							<div class="freight">
								(含运费：￥{{ Number(item.freight).toFixed(2) }})
							</div>
						</div>
						<div class="orderStatus flex-center column">
							<div v-if="items.isRefund != 1">
								<div
									class="order_details pointer"
									@click="refundBtn(items.id)"
									v-if="
										item.tradeStatus == 2 ||
										item.tradeStatus == 3 ||
										item.tradeStatus == 4 ||
										item.tradeStatus == 5 ||
										item.tradeStatus == 8 ||
										item.tradeStatus == 9
									"
								>
									退款/退换货
								</div>
							</div>
							<div
								class="order_details display pointer"
								v-if="items.isRefund == 1"
								@click="exchange(items.refundOrderId)"
							>
								售后详情
							</div>
							<div
								class="order_details display pointer"
								v-if="items.isRefund == 2"
								@click="exchange(items.refundOrderId)"
							>
								撤销退换
							</div>
							<div
								class="evaluation display pointer"
								v-if="item.tradeStatus == 4 && items.status == 0"
								@click="comment(items.id)"
							>
								评价
							</div>
							<div
								class="order_details pointer"
								@click="toDetails(items.orderId)"
							>
								订单详情
							</div>
						</div>
					</div>
				</div>
				<div class="operation display column">
					<div class="time_box flex-center" v-if="item.tradeStatus == 1">
						<img class="time_icon" src="@/assets/img/time.png" alt="" />
						{{ item.goodsTime }}
					</div>
					<div
						class="paymentBtn display pointer"
						v-if="item.tradeStatus == 1"
						@click="paymentBtn(item)"
					>
						立即付款
					</div>

					<div
						class="confirmReceipt display pointer"
						v-if="item.tradeStatus == 3"
						@click="affirm(item.id)"
					>
						确认收货
					</div>

					<div
						class="order_details display pointer"
						v-if="item.tradeStatus == 1"
						@click="cancelOrder(item.id)"
					>
						取消订单
					</div>
					<div
						class="invoice display pointer"
						v-if="
							(item.tradeStatus == 2 && item.isBill == 0) ||
							(item.tradeStatus == 3 && item.isBill == 0) ||
							(item.tradeStatus == 4 && item.isBill == 0) ||
							(item.tradeStatus == 5 && item.isBill == 0)
						"
						@click="invoiceBtn(item)"
					>
						申请开票
					</div>
					<div
						class="invoice display pointer"
						v-if="item.isBill == 1"
						@click="viewInvoice(item)"
					>
						查看发票
					</div>
				</div>
			</div>
		</div>
		<div class="pageBox" v-if="total >= 10">
			<Pages :total="total" :params="params" @jump="pageSkip"></Pages>
		</div>
		<div class="noData_box display column" v-if="total == 0">
			<img src="@/assets/img/noData.png" alt="" />
			<div class="hint">您暂时没有相关订单！</div>
		</div>
		<div v-if="invoiceShow">
			<Invoice
				:title="title"
				:invoiceData="invoiceData"
				@cancel="cancel"
				@saveBtn="saveBtn"
			></Invoice>
		</div>
		<div v-if="invoiceListShow">
			<SelectInvoice
				@close="close"
				@updateInvoice="updateInvoice"
				@modifyInvoice="modifyInvoice"
				@confirm="confirm"
				:invoiceList="invoiceList"
			>
			</SelectInvoice>
		</div>
	</div>
</template>
<script>
import Pages from '@/components/pages.vue'
import Invoice from '@/components/invoice.vue'
import SelectInvoice from '@/components/selectInvoice.vue'
export default {
	props: {
		orderstate: {},
		goodsName: {},
	},
	components: {
		Pages,
		Invoice,
		SelectInvoice,
	},
	data() {
		return {
			invoiceShow: false,
			invoiceListShow: false,
			timer: '',
			min: 0,
			sec: 0,
			params: {
				page: 1,
				limit: 10,
				tradeStatus: '',
				goodsName: '',
				token: this.$store.state.userData.token,
			},
			total: 0,
			goodsData: [
				{
					orderItemVOS: [{}],
				},
				{
					orderItemVOS: [{}, {}],
				},
			],
			invoiceList: [],
			invoiceData: {},
			title: '新增发票抬头',
			invoiceParams: {
				token: this.$store.state.userData.token,
				orderId: '',
				invoiceId: '',
			},
		}
	},
	watch: {
		orderstate: {
			handler(n, o) {
				if (n == 99) {
					this.params.tradeStatus = ''
				} else {
					this.params.tradeStatus = n
				}
				this.getOrderData()
			},
			//   immediate: true,
			//   deep: true // 深度监听父组件传过来对象变化
		},
		goodsName: {
			handler(n, o) {
				this.params.goodsName = n
				this.params.page = 1
				this.getOrderData()
			},
			//   immediate: true,
			//   deep: true // 深度监听父组件传过来对象变化
		},
	},
	mounted() {
		this.getOrderData()
	},
	beforeDestroy() {
		let that = this
		clearTimeout(that.timer)
		location.reload()
	},
	methods: {
		//    获取订单列表
		getOrderData() {
			this.api.findPageUserOrder(this.params).then((res) => {
				if (res.code == 0) {
					res.data.forEach((item) => {
						item.orderItemVOS.forEach((datas) => {
							datas.totalPrice =
								datas.totalPrice +
								datas.freight -
								datas.couponMoney -
								datas.integral
						})
					})
					this.goodsData = res.data
					this.total = res.count
					this.countdown()
				}
			})
		},
		countdown() {
			let that = this
			that.goodsData.forEach((item) => {
				if (item.tradeStatus == 1) {
					let end = Date.parse(new Date(item.submitTime)) //下单时间
					let now = Date.parse(new Date()) //获取当前时间
					end = end + 86400000
					const msec = end - now
					if (msec < 0) return
					let day = parseInt(msec / 1000 / 60 / 60 / 24)
					let hr = parseInt((msec / 1000 / 60 / 60) % 24)
					that.min = parseInt((msec / 1000 / 60) % 60)
					that.sec = parseInt((msec / 1000) % 60)
					hr = hr > 9 ? hr : '0' + hr
					that.min = that.min > 9 ? that.min : '0' + that.min
					that.sec = that.sec > 9 ? that.sec : '0' + that.sec
					let goodsTime = hr + '时' + that.min + '分钟' + that.sec + '秒'
					that.$set(item, 'goodsTime', goodsTime)
					if (that.min >= 0 && that.sec >= 0) {
						//倒计时结束关闭订单
						if (that.min == 0 && that.sec == 0) {
							clearTimeout(that.timer)
							location.reload()
						} else {
							that.timer = setTimeout(function () {
								that.countdown()
							}, 1000)
						}
					}
				}
			})
		},
		//去订单详情
		toDetails(id) {
			this.$router.push({
				path: '/userInfo/orderDetails',
				query: {
					orderId: id,
				},
			})
		},
		//取消订单
		cancelOrder(id) {
			this.$confirm('是否取消订单?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					let params = {
						orderId: id,
						token: this.$store.state.userData.token,
					}
					this.api.cancelOrder(params).then((res) => {
						this.$message.success('成功!')
						this.getOrderData()
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					})
				})
		},

		//确认收货
		affirm(id) {
			this.$confirm('是否确认收货?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					let params = {
						orderId: id,
						token: this.$store.state.userData.token,
					}
					this.api.confirmReceiptGoods(params).then((res) => {
						this.$message.success('成功!')
						this.getOrderData()
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					})
				})
		},
		// 删除订单
		delBtn(id) {
			this.$confirm('是否删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					let params = {
						orderId: id,
						token: this.$store.state.userData.token,
					}
					this.api.removedOrder(params).then((res) => {
						this.$message.success('删除成功!')
						this.getOrderData()
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除',
					})
				})
		},
		//评论
		comment(id) {
			this.$router.push({
				path: '/userInfo/orderComment',
				query: {
					orderId: id,
				},
			})
		},
		//退款、退货
		refundBtn(id) {
			this.$router.push({
				path: '/userInfo/returnGoods',
				query: {
					orderId: id,
				},
			})
		},
		paymentBtn(item) {
			this.$router.push({
				path: '/cart/orderPay',
				query: {
					orderNum: item.orderCode,
					price: item.sumSalePrice,
				},
			})
		},
		//退款详情
		exchange(id) {
			this.$router.push({
				path: '/userInfo/returnGoodsDetails',
				query: {
					orderId: id,
					type: '退款',
				},
			})
		},
		//分页
		pageSkip(page) {
			this.params = page
			this.getOrderData()
		},
		//去商品详情
		toGoods(id) {
			this.$router.push({
				path: '/mall/goodsDetails',
				query: {
					id: id,
				},
			})
		},
		getInvoiceData() {
			//获取发票信息
			this.api
				.invoiceList({ token: this.$store.state.userData.token })
				.then((res) => {
					if (res.code == 0) {
						if (res.data.length != 0) {
							this.invoiceListShow = true
							this.invoiceList = res.data
							//    res.data.forEach(item => {
							//        if (item.defaultState == 1) {
							//            this.params.invoiceId = item.id
							//        }
							//    })
						} else {
							this.invoiceShow = true
						}
					}
				})
		},
		// 开发票
		invoiceBtn(item) {
			this.invoiceParams.orderId = item.id
			this.getInvoiceData()
		},
		//取消
		cancel() {
			this.invoiceShow = false
		},
		//修改发票
		modifyInvoice(item) {
			this.invoiceShow = true
			this.invoiceData = item
			this.title = '修改发票'
		},
		//保存
		saveBtn() {
			this.invoiceShow = false
			this.getInvoiceData()
		},
		// 更新发票列表
		updateInvoice() {
			this.getInvoiceData()
		},
		// 关闭发票列表
		close() {
			this.invoiceListShow = false
		},
		// 确定
		confirm(id) {
			this.invoiceParams.invoiceId = id

			this.api.applyMakingInvoice(this.invoiceParams).then((res) => {
				if (res.code == 0) {
					this.$message.success('开票成功！')
					this.invoiceListShow = false
					this.getOrderData()
				}
			})
		},
		// 查看发票
		viewInvoice() {
			let data = {
				isShow: 16,
			}
			sessionStorage.setItem('broadside', JSON.stringify(data))
			this.$store.commit('getBroadside', data)
		},
	},
}
</script>
<style lang="scss" scoped>
.order_box {
	.order_list {
		border: 1px solid #eaeaea;
		margin-bottom: 20px;
		.head {
			width: 100%;
			height: 27px;
			background: #f4f5f5;
			padding-left: 23px;
			padding-right: 18px;
			box-sizing: border-box;
			.head_time_orderCode {
				font-size: 12px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #333333;
				line-height: 30px;
				.order_time {
				}
				.order_code {
					color: #666666;
					margin-left: 20px;
				}
			}
			.head_fl {
				img {
					width: 17px;
					height: 17px;
					margin-left: 10px;
				}
			}
		}
		.goods_list_box:last-child {
			border-bottom: none !important;
		}
		.goods_list_box {
			border-bottom: 1px solid #eaeaea;
			.goods_list {
				width: 525px;
				border-right: 1px solid #eaeaea;
				position: relative;
				.goodsDelFlag {
					width: 918px;
					height: 100%;
					background: url('../../../assets/img/bg5.png') no-repeat;
					background-size: 100% 100%;
					position: absolute;
					top: 0px;
					left: 0px;
					z-index: 3;
				}
				.lists {
					padding: 22px 21px 20px 20px !important;
				}
				.list {
					padding: 22px 21px 25px 20px;
					box-sizing: border-box;
					.goodsImg {
						width: 78px;
						img {
							width: 78px;
							height: 78px;
						}
					}
					.goods_name_specification {
						width: 231px;
						margin-left: 17px;
						.goods_name {
							width: 231px;
							height: 31px;
							font-size: 12px;
							color: #333333;
						}
						.goods_specification {
							width: 231px;
							height: 31px;
							font-size: 12px;
							color: #888888;
							margin-top: 13px;
						}
					}
					.goodsPrice {
						width: 100px;
						font-size: 14px;
						color: #666666;
						text-align: right;
					}
					.goodsNum {
						font-size: 14px;
						color: #666666;
						margin-left: 41px;
					}
				}
			}
			.totalPrice {
				width: 145px;
				font-size: 14px;
				border-right: 1px solid #eaeaea;
				.price {
					color: #ec454c;
					font-family: Microsoft YaHei;
					font-weight: bold;
					margin-top: 22px;
				}
				.freight {
					color: #666666;
					margin-top: 5px;
				}
			}
			.orderStatus {
				width: 105px;
				font-size: 12px;
				color: #666666;
				line-height: 22px;
				padding-top: 22px;
				box-sizing: border-box;
				border-right: 1px solid #eaeaea;
				.being_processed {
					color: #ec454c;
				}
			}
		}
		.operation {
			width: 142px;
			box-sizing: border-box;
			.paymentBtn {
				width: 80px;
				height: 28px;
				background: #ec454c;
				border-radius: 3px;
				font-size: 12px;
				color: #ffffff;
				margin-bottom: 8px;
			}
			.cancelOrder {
				font-size: 12px;
				color: #333333;
			}
			.confirmReceipt {
				width: 80px;
				height: 28px;
				background: #66b6ff;
				border-radius: 3px;
				font-size: 12px;
				color: #ffffff;
				margin-bottom: 10px;
			}
			.invoice {
				font-size: 12px;
				color: #333;
			}
			.viewInvoice {
				font-size: 12px;
				color: #666666;
			}
		}
	}
	.noData_box {
		margin-top: 88px;
		img {
			width: 186px;
			height: 189px;
		}
		.hint {
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #888888;
			margin-top: 28px;
		}
	}
}
.cancelOrder {
	font-size: 14px;
	color: #333333;
}
.order_status {
	font-size: 14px;
	color: #333333;
	line-height: 14px;
}
.order_details {
	font-size: 14px;
	color: #333333;
	line-height: 14px;
	margin-bottom: 8px;
}
.time_box {
	font-size: 12px;
	color: #888888;
	margin-bottom: 10px;
}
.time_icon {
	width: 14px;
	height: 14px;
	margin-right: 5px;
}
.evaluation {
	width: 64px;
	height: 28px;
	border: 1px solid #cccccc;
	border-radius: 3px;
	font-size: 12px;
	color: #666666;
	margin-bottom: 10px;
}
</style>
