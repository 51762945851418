<template>
	<!-- 开票记录组件 -->
	<div id="billRecord">
		<div class="headline">
			<span class="headline_info" @click="billToRecord(15)">发票信息</span
			><span class="headline_rise">开票记录</span>
		</div>
		<div class="billTitle">
			<span class="billTitle_details">订单详情</span
			><span class="billTitle_type">发票类型</span
			><span class="billTitle_state">状态</span
			><span class="billTitle_act">操作</span>
		</div>
		<!-- 显示记录 -->
		<div class="showBillBox">
			<div class="showBill" v-for="item in findInvoiceLists" :key="item.id">
				<div class="timeNumber">
					<span class="billTime">{{ item.createTime }}</span>
					<span class="billNumber">订单号：{{ item.orderCode }}</span>
				</div>
				<div class="billDetails">
					<div class="Details">
						<div
							class="goodsDetails"
							v-for="itemD in item.goodsListVO"
							:key="itemD.id"
						>
							<div class="img"><img :src="itemD.coverUrl" alt="" /></div>
							<div class="goodsTitle" v-html="itemD.productName"></div>
							<div class="goodsNumber"><span>x</span>{{ itemD.goodsNum }}</div>
						</div>
					</div>
					<div class="type">
						{{
							item.invoiceType == '1'
								? '个人发票'
								: item.invoiceType == '2'
								? '企业发票'
								: '增值税发票'
						}}
					</div>
					<div :class="item.invoiceState == '1' ? 'red' : 'yet'">
						{{ item.invoiceState == '1' ? '待开票' : '已开票' }}
					</div>
					<el-button type="text" @click="findBillDetail(item.id)"
						>发票详情</el-button
					>
					<!-- 弹框 -->
					<el-dialog
						title="发票详情"
						:visible.sync="dialogVisible"
						width="30%"
						:before-close="handleClose"
					>
						<p>
							发票类型:<span class="totalSpan">{{ billDetail.billType }}</span>
						</p>
						<p>
							发票抬头:<span class="totalSpan">{{
								billDetail.invoiceHead
							}}</span>
						</p>
						<p v-if="billDetail.invoiceType == 2 ? true : false">
							购方税号:<span class="totalSpan">{{
								billDetail.buyerTaxId
							}}</span>
						</p>
						<p>
							开票日期:<span class="totalSpan">{{
								billDetail.createTime
							}}</span>
						</p>
						<p>
							发票代码:<span class="totalSpan">{{
								billDetail.identifier
							}}</span>
						</p>
						<p>
							发票号码:<span class="totalSpan">{{
								billDetail.invoiceNumber
							}}</span>
						</p>
						<p>
							发票金额:<span class="totalSpan"
								>￥{{ billDetail.sumSalePrice }}</span
							>
						</p>
						<span
							slot="footer"
							class="dialog-footer"
							v-if="billDetail.invoiceState == 1 ? false : true"
						>
							<el-button
								type="primary"
								@click="downloadPDF(billDetail.invoiceUrl)"
								>下载发票</el-button
							>
						</span>
					</el-dialog>
				</div>
			</div>
		</div>
		<div class="hint" v-if="findInvoiceLists.length <= 0">
			用户暂无开票记录~
		</div>
		<!-- 分页 -->
		<div class="pageBox" v-if="total > 0">
			<Pages :total="total" :params="params" @jump="pageSkip"></Pages>
		</div>
	</div>
</template>

<script>
import Pages from '@/components/pages.vue'
export default {
	name: 'BillRecord',
	components: {
		Pages,
	},
	data() {
		return {
			// 第三方组件
			dialogVisible: false,
			// 用户token
			token: '',
			// 开票记录列表
			findInvoiceLists: [],
			// 总条数
			total: 0,
			params: {
				token: this.$store.state.userData.token,
				page: 1,
				limit: 10,
			},
			// 当前页
			page: '1',
			// 每页显示数
			limit: 10,
			// 发票详情
			billDetail: {},
		}
	},
	mounted() {
		this.recordInit()
	},
	methods: {
		// 查询开票记录
		recordInit() {
			// 获取用户token
			this.token = this.$store.state.userData.token
			this.api.findOrderInvoiceLists(this.params).then((res) => {
				this.findInvoiceLists = res.data
				this.total = res.count
			})
		},
		// 查询发票详情事件
		findBillDetail(id) {
			this.dialogVisible = true
			this.api.queryBillById({ id }).then((res) => {
				let data = res.data

				if (data.invoiceType == 1) {
					data.billType = '个人发票'
				} else if (data.invoiceType == 2) {
					data.billType = '企业发票'
				} else {
					data.billType = '增值税专用发票'
				}
				this.billDetail = data
			})
		},
		// 分页
		pageSkip(page) {
			this.params = page
			this.recordInit()
		},
		// 发票详情关闭事件
		handleClose(done) {
			this.$confirm('确认关闭？')
				.then((_) => {
					done()
				})
				.catch((_) => {})
		},
		billToRecord(num) {
			// 向父组件传值
			this.$emit('changeBillIR', num)
		},
		// 下载发票
		downloadPDF(url) {
			if (url != '' || url != null) {
				window.open(url, '_blank')
			} else {
				this.$message.error('暂未开票')
			}
		},
	},
}
</script>

<style lang="scss" scoped>
#billRecord {
	min-height: 880px;
	background-color: white;
	padding-bottom: 15px;
	position: relative;
	.headline {
		height: 60px;
		border-bottom: 1px solid #ededed;
		margin-bottom: 10px;
		.headline_info {
			margin-left: 31px;
			margin-right: 20px;
			line-height: 60px;
			font-size: 16px;
			font-family: Microsoft YaHei;
			cursor: pointer;
		}
		.headline_rise {
			line-height: 56px;
			font-size: 16px;
			font-family: Microsoft YaHei;
			color: red;
			display: inline-block;
			border-bottom: 2px solid red;
		}
	}
	.billTitle {
		background-color: #f4f4f4;
		width: 897px;
		height: 42px;
		margin: 0 auto;
		margin-bottom: 11px;
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		display: flex;
		.billTitle_details {
			flex: 3;
			margin-left: 91px;
			line-height: 42px;
		}
		.billTitle_type {
			flex: 1.5;
			text-align: center;
			line-height: 42px;
		}
		.billTitle_state {
			flex: 1.5;
			text-align: center;
			line-height: 42px;
		}
		.billTitle_act {
			flex: 1.5;
			text-align: center;
			line-height: 42px;
		}
	}
	.hint {
		color: #666;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -10px;
		margin-left: -70px;
	}
	.showBillBox {
		min-height: 700px;
		width: 915px;
		margin-left: 41px;
		.showBill {
			width: 896px;
			min-height: 129px;
			margin-top: 11px;
			border: 1px solid #f4f4f4;
			.timeNumber {
				height: 27px;
				line-height: 27px;
				background-color: #f4f4f4;
				font-size: 12px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #888888;
				.billTime {
					margin-left: 23px;
					margin-right: 40px;
				}
			}
			.billDetails {
				display: flex;
				align-items: flex-start;
				font-size: 12px;
				.Details {
					// flex: 2;
					// color: #333333;
					// 优化样式-最多显示三个产品
					flex: 2.8;
					color: #333333;
					overflow-y: auto;
					max-height: 200px;
					.goodsDetails {
						display: flex;
						align-items: center;
						margin-bottom: 11px;
						.img {
							width: 78px;
							height: 78px;
							border: 1px solid #f8f8f8;
							margin-top: 11px;
							margin-left: 30px;
							margin-right: 23px;
							img {
								width: 100%;
							}
						}
						.goodsNumber {
							font-size: 12px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #888888;
							margin-left: 52px;
						}
					}
					.goodsTitle {
						width: 230px;
						height: 33px;
						// 溢出省略号
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}
				}
				.Details::-webkit-scrollbar {
					display: none;
				}
				.type {
					flex: 1;
					color: #888888;
					text-align: center;
					margin-top: 39px;
				}
				.yet {
					flex: 1;
					color: #7bbc52;
					text-align: center;
					margin-top: 39px;
				}
				.red {
					flex: 1;
					color: #e62129;
					text-align: center;
					margin-top: 39px;
				}
				.act {
					flex: 1;
					color: #333333;
					text-align: center;
					margin-top: 39px;
				}
				::v-deep.el-button--text {
					flex: 1;
					margin-top: 28px;
				}
				::v-deep.el-dialog__wrapper {
					.el-dialog {
						.el-dialog__header {
							padding-top: 10px;
							padding-bottom: 10px;
							padding-left: 30px;
							background: #f4f4f4;
							.el-dialog__title {
								font-size: 14px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #000000;
							}
							.el-dialog__headerbtn {
								top: 10px;
								right: 15px;
								font-size: 25px;
							}
						}
						.el-dialog__body {
							padding-left: 50px;
							P {
								padding-bottom: 10px;
								font-size: 14px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #666666;
								.totalSpan {
									margin-left: 50px;
								}
							}
							.none {
								display: none;
							}
						}
						.el-dialog__footer {
							text-align: left;
							padding-top: 0;
							padding-right: 160px;
							padding-left: 160px;
							padding-bottom: 20px;
							.dialog-footer {
								.el-button--primary {
									background-color: #e1251b;
									border-color: #e1251b;
								}
							}
						}
					}
				}
			}
		}
	}
	.block {
		width: 100%;
		margin-top: 20px;
		text-align: center;
		position: absolute;
		bottom: 0;
		.el-pagination {
			display: inline-block;
		}
	}
}
</style>