<template>
    <!-- 意见反馈组件 -->
    <div id="idea">
        <div class="title">
            <span>意见反馈</span>
        </div>
        <div class="title_text">
            <p>尊敬的客户：</p>
            <p>
                您好！为了给您提供更好的服务，我们希望收集您使用筑手建筑防护超市的看法或建议。对您的配合和支持表示衷心感谢！
            </p>
        </div>
        <div class="idea_body">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="问题类型:" prop="region">
                    <el-select v-model="ruleForm.region" placeholder="请选择">
                                            <el-option v-for="item in selectLists" :key="item.id" :label="item.typeName"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                                <el-form-item label="问题描述:" prop="desc">
                    <el-input type="textarea" v-model="ruleForm.desc" placeholder="请输入问题描述"></el-input>
                </el-form-item>
                <el-form-item class="uploadImgBox" label="相关图片:" prop="url">
                                    <el-input disabled v-if="false" v-model="ruleForm.url"></el-input>
                    <div class="imgList" v-show="imgUrlList" v-for="(item, index) in imgUrlList" :key="index">
                        <img :src="item" alt="" />
                        <div class="deleteIcon" @click="delImg(index)">
                            <i class="el-icon-delete"></i>
                        </div>
                    </div>
                    <baseUpload v-if="imgUrlList.length >= 5 ? false : true" class="uploadImg"
                        @uploadImgUrl="uploadImgUrl" />
                    <!-- 预览图片窗口 -->
                    <!-- <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog> -->
                </el-form-item>
                <el-form-item label="联系方式:" prop="phone">
                    <el-input v-model="ruleForm.phone" placeholder="请输入手机号码"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">提交反馈</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import baseUpload from '@/components/baseUpload.vue'
export default {
    name: 'Idea',
    components: {
        baseUpload,
    },
    data() {
        return {
            // 意见反馈类别
            selectLists: [],
            // 当前用户token
            token: '',
            // 上传图片
            imgUrl: '',
            // 上传图片地址集合
            imgUrlList: [],
            // 第三方组件属性
            ruleForm: {
                phone: '', //手机号
                region: '', //意见类别
                desc: '', //意见描述
                url: '', //意见图片
            },
            rules: {
                region: [{ required: true, message: '请选择类型', trigger: 'change' }],
                desc: [{ required: true, message: '请输入问题描述', trigger: 'blur' }],
                phone: [
                    { required: true, message: '请输入联系方式', trigger: 'blur' },
                    {
                        pattern: /^1[3|5|7|8|9]\d{9}$/,
                        message: '请输入11位正确号码格式',
                        trigger: 'change',
                    },
                ],
                // 假校验-只需要一个校验提示    输入框隐藏
                url: [{ required: true, message: '请上传图片', trigger: 'change' }],
            },
            // 预览图片
            // dialogImageUrl: '',
            // dialogVisible: false,
        }
    },

    mounted() {
        this.selectListsInit()
    },

    methods: {
        // 获取意见反馈类别
        selectListsInit() {
            // 获取用户token
            this.token = this.$store.state.userData.token
            this.api.selectLists().then((res) => {
                this.selectLists = res.data
            })
        },
        // // 获取上传图片地址
        // gainImgUrl(file,fileList) {
        //
        //     let imgUrl = fileList.url.substring(fileList.url.indexOf(':')+1)
        //     this.imgUrl = imgUrl
        // },
        uploadImgUrl(url) {
            // 地址push进集合

            this.imgUrlList.push(url)
            this.ruleForm.url = url
        },
        // 提交反馈点击事件
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 判断是否上传图片
                    if (this.imgUrlList == '') {
                        this.$message({
                            message: '请上传反馈图片',
                            type: 'error',
                        })
                        return
                    }
                    // 判断是否正确号码
                    let regPhone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
                    if (!regPhone.test(this.$refs[formName].model.phone)) {
                        this.$message({
                            message: '请输入正确格式的手机号码',
                            type: 'error',
                        })
                        return
                    }

                    // 新增意见反馈
                    this.api
                        .insertFeedback({
                            feedbackTypeId: this.$refs[formName].model.region,
                            phone: this.$refs[formName].model.phone,
                            opinionDescribe: this.$refs[formName].model.desc,
                            srcUrl: this.imgUrlList,
                            token: this.token,
                        })
                        .then((res) => {
                            // 清空反馈内容
                            this.$refs[formName].resetFields()
                            if (res.code != 0) {
                                this.$message({
                                    message: res.msg,
                                    type: 'error',
                                })
                                return
                            }
                            this.$message({
                                message: '反馈成功！',
                                type: 'success',
                            })
                            // 反馈成功-清除信息
                            this.imgUrlList = []
                        })
                } else {
                    return false
                }
            })
        },
        // // 删除图片事件
        // handleRemove(file, fileList) {
        //     console.log(file, fileList);
        // },
        // 删除图片
        delImg(id) {
            let imgUrlList = this.imgUrlList
            let indexNum
            imgUrlList.forEach((item, index) => {
                if (id == index) {
                    indexNum = index
                }
            })
            imgUrlList.splice(indexNum, 1)
        },

        // // 预览图片事件
        // handlePictureCardPreview(file) {
        //     this.dialogImageUrl = file.url;
        //     this.dialogVisible = true;
        // }
    },
}
</script>

<style lang="scss" scoped>
#idea {
    height: 880px;
    background-color: white;

    .title {
        height: 60px;
        line-height: 60px;
        border-bottom: 1px solid #eaeaea;
        margin-bottom: 37px;

        span {
            width: 64px;
            height: 17px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #e1251b;
            line-height: 30px;
            margin-left: 43px;
        }
    }

    .title_text {
        width: 816px;
        margin: 0 auto;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #666666;
        line-height: 30px;
    }

    .idea_body {
        ::v-deep .el-form {
            width: 806px;
            margin: 0 auto;
            margin-top: 50px;

            .el-form-item {
                .el-form-item__content {
                    display: flex;
                    align-items: center;

                    .el-input {
                        width: 272px;
                        height: 40px;
                        margin-left: 28px;
                    }

                    .el-textarea {
                        margin-left: 28px;

                        .el-textarea__inner {
                            width: 503px;
                            height: 199px;
                            max-height: 331px;
                        }
                    }

                    .el-upload {
                        width: 98px;
                        height: 98px;

                        .el-icon-plus {
                            position: relative;
                            top: -15px;
                        }
                    }

                    .el-button {
                        width: 134px;
                        height: 40px;
                        background: #e1251b;
                        border-radius: 3px;
                        margin-left: 28px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                    }

                    .el-button--primary {
                        border-color: #e1251b;
                    }

                    .el-upload-list {
                        .el-upload-list__item {
                            width: 98px;
                            height: 98px;

                            img {
                                width: 98px;
                                height: 98px;
                            }

                            .el-upload-list__item-actions {
                                width: 98px;
                                height: 98px;
                            }
                        }
                    }

                    .el-upload-list--picture-card {
                        margin-left: 30px;
                    }

                    .uploadImg {
                        margin-left: 30px;
                        display: inline-block;

                        .imgBox {
                            width: 100px;
                            height: 100px;
                        }
                    }

                    .imgList {
                        position: relative;
                        display: inline-block;
                        border: 1px solid #ccc;
                        margin-left: 30px;

                        img {
                            width: 100px;
                            height: 100px;
                        }

                        .deleteIcon {
                            position: absolute;
                            top: 0;
                            right: 0;
                            height: 25px;
                            width: 25px;
                            background: rgba($color: red, $alpha: 0.7);
                            display: flex;
                            border-radius: 0 0 0 100%;

                            i {
                                margin-left: 6px;
                                margin-top: 3px;
                                color: white;
                            }
                        }

                        .deleteIcon:hover {
                            background: red;
                        }
                    }

                    .el-form-item__error {
                        margin-left: 30px;
                    }
                }
            }
        }
    }
}
</style>