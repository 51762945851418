<template>
	<!-- 地址管理组件 -->
	<div id="IPam">
		<div class="headline">
			<span class="headline_info">地址管理</span>
			<button class="btn">
				<!-- 地址弹框 -->
				<el-button
					class="button"
					type="text"
					@click="dialogFormVisible = true"
					style="color: white; height: 34px"
					>新增地址</el-button
				>
				<el-dialog
					title="新增地址"
					:before-close="handleClose"
					:visible.sync="dialogFormVisible"
				>
					<el-form
						:model="form"
						label-position="right"
						:rules="rules"
						ref="form"
					>
						<el-form-item class="takeGoods" label="收货人:" prop="name">
							<el-input
								v-model="form.name"
								placeholder="请输入收货人姓名"
							></el-input>
						</el-form-item>
						<el-form-item label="手机号码:" prop="phone">
							<el-input
								v-model="form.phone"
								maxlength="11"
								placeholder="请输入手机号码"
							></el-input>
						</el-form-item>
						<el-form-item label="所在地区" prop="province">
							<el-cascader
								size="large"
								:options="options"
								:props="props"
								v-model="AreaArray"
								@change="selectArea"
								placeholder="选择您所在地址"
								clearable
							></el-cascader>
						</el-form-item>

						<el-form-item label="详细地址:" prop="detailAddress">
							<el-input
								v-model="form.detailAddress"
								placeholder="请输入详细地址"
							></el-input>
						</el-form-item>
						<el-form-item class="mail" label="邮政编码:" prop="mail">
							<el-input
								v-model="form.mail"
								placeholder="请输入邮政编码"
							></el-input>
						</el-form-item>
						<el-form-item>
							<el-checkbox
								v-model="form.type"
								label="设为默认地址"
								name="type"
							></el-checkbox>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button @click="cancel">取 消</el-button>
						<el-button type="primary" @click="resetForm('form')"
							>保存</el-button
						>
					</div>
				</el-dialog>
			</button>
		</div>
		<div class="billTitle">
			<span class="billTitle_details">收货人</span
			><span class="billTitle_type">所在地区</span
			><span class="detailAddress">详细地址</span
			><span class="billTitle_types">电话/手机</span
			><span class="billTitle_act">操作</span>
		</div>
		<!-- 渲染地址列表 -->
		<div class="details_info" v-for="item in addressList" :key="item.id">
			<div class="infoTitle">
				<span>{{ item.userName }}</span>
			</div>
			<div class="bill_type">
				<span>{{ item.province + item.city + item.district }}</span>
			</div>
			<div class="addressDetail">
				<span>{{ item.detailAddress }}</span>
			</div>
			<div class="bill_types">{{ item.userPhone }}</div>
			<div class="detail_info_oper">
				<button class="alter" @click="handleEdit(item)">修改</button>
				<span>|</span>
				<button class="delete" @click="handleDelete(item.id)">删除</button>
				<span
					:class="item.addressState == 1 ? 'default_back' : 'default'"
					@click="handleDefault(item.id)"
					>{{ item.addressState == 1 ? '默认地址' : '设为默认' }}</span
				>
			</div>
		</div>
		<div class="hint" v-if="addressList.length <= 0">用户暂未新增地址~</div>
		<!-- 分页 -->
		<div class="block" v-show="this.totalTrack >= 9">
			<el-pagination
				@size-change="handleSizeChangeTrack"
				@current-change="handleCurrentChangeTrack"
				:page-size="9"
				layout="prev, pager, next, jumper"
				:totalTrack="totalTrack"
			>
			</el-pagination>
		</div>
	</div>
</template>

<script>
import areaJSON from '../../../http/area.json'
export default {
	name: 'IPam',

	data() {
		return {
			// 新增地址
			dialogFormVisible: false,
			form: {
				name: '', //收货人
				phone: '', //手机号码
				detailAddress: '', //详细地址
				mail: '', //邮政编码
				type: false, //地址默认状态(true为默认地址，false非默认地址)
				province: '', // 省
				city: '', // 市
				district: '', // 区
			},
			formLabelWidth: '120px',
			rules: {
				name: [
					{ required: true, message: '请输入收货人姓名', trigger: 'blur' },
				],
				phone: [
					{ required: true, message: '请输入11位手机号码', trigger: 'blur' },
					{
						pattern: /^1[3|5|7|8|9]\d{9}$/,
						message: '请输入正确的号码格式',
						trigger: 'change',
					},
				],
				province: [
					{ required: true, message: '请选择所在地区', trigger: 'change' },
				],

				detailAddress: [
					{ required: true, message: '请输入详细地址', trigger: 'blur' },
				],
				mail: [
					{ required: false, message: '请输入6位邮政编码', trigger: 'blur' },
					{
						pattern: /^[1-9][0-9]{5}$/,
						message: '请输入正确格式邮政编码',
						trigger: 'blur',
					},
				],
			},
			// 用户token
			token: '',
			// 分页-总条数
			totalTrack: '',
			// 当前页
			page: '1',
			// 地址列表
			addressList: [],
			// 判断标识(是修改地址信息还是新增地址)
			addressFlag: false,
			// 修改地址信息-地址id
			id: '',
			// 三级地区联动
			pcaList: {},
			options: areaJSON,
			props: {
				children: 'children',
				label: 'name',
				value: 'name',
			},
			AreaArray: [],
		}
	},

	mounted() {
		this.addressInit()
		// 获取统计局省市区
		let pca = require('../../../http/pca.json')
		this.pcaList = pca
	},
	methods: {
		// 查询用户地址
		addressInit() {
			this.token = this.$store.state.userData.token
			this.api
				.findByUserIdAddress({
					token: this.token,
					page: this.page,
					limit: '9',
				})
				.then((res) => {
					this.totalTrack = res.count
					this.addressList = res.data
				})
		},
		// 清楚form表单
		reset() {
			this.form = {
				name: '', //收货人
				phone: '', //手机号码
				detailAddress: '', //详细地址
				mail: '', //邮政编码
				type: false, //地址默认状态(true为默认地址，false非默认地址)
				province: '', // 省
				city: '', // 市
				district: '', // 区
			}
			this.AreaArray = []
		},
		// 分页
		handleSizeChangeTrack(val) {
			// console.log(`每页 ${val} 条`);
		},
		handleCurrentChangeTrack(val) {
			// console.log(`当前页: ${val}`);
			this.page = val
			// 切换页数发送请求
			this.addressInit()
		},
		//新增地址按钮事件
		resetForm(Form) {
			this.$refs[Form].validate((valid) => {
				if (valid) {
					this.dialogFormVisible = false
					if (this.addressFlag == true) {
						let data = this.$refs[Form].model
						// 存储地址状态标识
						let addressState
						if (this.$refs[Form].model.type == true) {
							addressState = 1
						} else {
							addressState = 2
						}

						// 修改地址信息
						this.api
							.updateAddress({
								userName: data.name,
								userPhone: data.phone,
								province: data.province,
								city: data.city,
								district: data.district,
								detailAddress: data.detailAddress,
								zipCode: data.mail,
								addressState: addressState,
								token: this.token,
								id: this.id,
							})
							.then((res) => {
								if (res.code == 0) {
									this.$message({
										message: '地址新增成功',
										type: 'success',
									})
									this.addressInit()
								}
								if (res.code == 500) {
									this.$message(res.msg)
								}
							})
						this.addressFlag = false
					} else {
						let data = this.$refs[Form].model
						// 存储地址状态标识
						let addressState
						if (data.type == true) {
							addressState = 1
						} else {
							addressState = 2
						}

						// 新增地址
						this.api
							.insertAddress({
								userName: data.name,
								userPhone: data.phone,
								province: data.province,
								city: data.city,
								district: data.district,
								detailAddress: data.detailAddress,
								zipCode: data.mail,
								addressState,
								token: this.token,
							})
							.then((res) => {
								if (res.code == 0) {
									this.$message({
										message: '地址新增成功',
										type: 'success',
									})
									this.addressInit()
								}
								if (res.code == 500) {
									this.$message(res.msg)
								}
							})
					}
					// 清空form对象
					this.reset()
					return true
				} else {
					return false
				}
			})
		},
		// 弹框取消按钮
		cancel() {
			this.dialogFormVisible = false
			this.reset()
			this.addressFlag = false
		},
		// 修改
		handleEdit(item) {
			this.dialogFormVisible = true
			if (item.addressState == '1') {
				this.form.type = true
			} else {
				this.form.type = false
			}
			this.form.name = item.userName
			this.form.phone = item.userPhone
			this.form.mail = item.zipCode
			this.form.province = item.province //省
			this.form.city = item.city // 市
			this.form.district = item.district // 区
			this.form.detailAddress = item.detailAddress // 详细地址
			this.AreaArray = [item.province, item.city, item.district]
			// true为修改地址信息，false为新增地址
			this.addressFlag = true
			// 地址id
			this.id = item.id
		},
		handleDelete(id) {
			// 删除发票
			this.api
				.deleteAddress({
					token: this.token,
					id: id,
				})
				.then((res) => {
					this.addressInit()
				})
		},
		handleDefault(id) {
			// 更改地址状态
			this.api
				.updateState({
					token: this.token,
					id,
				})
				.then((res) => {
					this.addressInit()
				})
		},
		// 弹框关闭按钮
		handleClose(done) {
			this.$confirm('确认关闭？')
				.then((_) => {
					done()
					this.reset()
					this.addressFlag = false
				})
				.catch((_) => {})
		},
		// 选择省市区
		selectArea(val) {
			this.form.province = val[0]
			this.form.city = val[1]
			this.form.district = val[2]
		},
	},
}
</script>

<style lang="scss" scoped>
#IPam {
	width: 978px;
	height: 880px;
	background-color: white;
	position: relative;
	.hint {
		color: #666;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -10px;
		margin-left: -80px;
	}
	.details_info {
		width: 927px;
		height: 45px;
		margin: 0 auto;
		border-bottom: 1px solid #eaeaea;
		font-size: 12px;
		font-family: Microsoft YaHei;
		color: #333333;
		display: flex;
		align-items: center;
		.infoTitle {
			flex: 1;
			text-align: center;
			span {
				word-break: break-word;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
		}
		.bill_type {
			flex: 2;
			text-align: center;
			span {
				word-break: break-word;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
		}
		.bill_types {
			flex: 1;
			text-align: center;
			overflow: hidden;
		}
		.addressDetail {
			flex: 2;
			text-align: center;
			span {
				word-break: break-word;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
		}
		.detail_info_oper {
			flex: 2;
			text-align: center;
			button {
				cursor: pointer;
				font-size: 12px;
				font-family: Microsoft YaHei;
				color: #333333;
			}
			span {
				color: #eaeaea;
				margin-left: 10px;
				margin-right: 10px;
			}
			.delete {
				margin-right: 56px;
			}
			// 默认地址样式
			.default_back {
				box-sizing: border-box;
				padding: 6px 5px;
				background: rgba(208, 208, 208, 0.49);
				border-radius: 3px;
				font-size: 12px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				cursor: hand;
			}
			.default {
				cursor: pointer;
				font-size: 12px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
			}
		}
	}
	.headline {
		height: 60px;
		border-bottom: 1px solid #ededed;
		margin-bottom: 10px;
		.btn {
			cursor: pointer;
			width: 100px;
			height: 34px;
			background: #e1251b;
			border-radius: 3px;
			font-size: 12px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #ffffff;
			margin-left: 672px;
			.button {
				padding: 0;
			}
			// 新增地址组件样式
			::v-deep.el-dialog {
				width: 724px;
				height: 630px;
				.el-dialog__header {
					width: 100%;
					padding: 0;
					background: #f4f4f4;
					float: left;
					.el-dialog__title {
						float: left;
						margin-left: 40px;
						font-size: 14px;
						font-weight: 400;
						color: #000;
					}
					.el-dialog__headerbtn {
						font-size: 20px;
						top: 2px;
						right: 4px;
					}
				}
				.el-dialog__body {
					padding-bottom: 0;
					padding-top: 60px;
					.el-form {
						margin-left: 20px;
						.resource {
							.el-form-item__content {
								.el-radio-group {
									margin-top: 13px;
								}
							}
						}
						.el-form-item {
							.el-form-item__content {
								line-height: 0;
								.el-input {
									width: 452px;
									.el-input__inner {
										width: 452px;
										height: 40px;
									}
								}
								.el-checkbox {
									float: left;
									margin-left: 147px;
								}
								// 三级联动样式
								.el-select {
									width: 140px;
									float: left;
									.el-input--suffix {
										width: 140px;
										.el-input__inner {
											width: 140px;
										}
									}
								}
								.province {
									margin-left: 65px;
								}
								.el-form-item__error {
									margin-left: 145px;
								}
							}
						}
						.mail {
							margin-left: 10px;
						}
						.takeGoods {
							.el-form-item__content {
								.el-input {
									margin-left: 10px;
								}
							}
						}
						.region {
							margin-left: 10px;
						}
					}
				}
				.el-dialog__footer {
					.dialog-footer {
						float: left;
						margin-left: 167px;
					}
				}
			}
		}
		.headline_info {
			margin-left: 31px;
			margin-right: 20px;
			line-height: 56px;
			font-size: 16px;
			font-family: Microsoft YaHei;
			color: red;
			display: inline-block;
			border-bottom: 2px solid red;
		}
		.headline_rise {
			line-height: 60px;
			font-size: 16px;
			font-family: Microsoft YaHei;
		}
	}
	.billTitle {
		background-color: #f4f4f4;
		width: 927px;
		height: 42px;
		margin: 0 auto;
		margin-bottom: 11px;
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		display: flex;
		.billTitle_details {
			flex: 1;
			text-align: center;
			line-height: 42px;
		}
		.billTitle_type {
			flex: 2;
			text-align: center;
			line-height: 42px;
		}
		.billTitle_types {
			flex: 1;
			text-align: center;
			line-height: 42px;
		}
		.detailAddress {
			flex: 2;
			text-align: center;
			line-height: 42px;
		}
		.billTitle_act {
			flex: 2;
			text-align: center;
			line-height: 42px;
		}
	}
	.block {
		position: absolute;
		bottom: 0;
		width: 100%;
		text-align: center;
		::v-deep.el-pagination {
			display: inline-block;
		}
	}
}
</style>