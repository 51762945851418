<template>
	<!-- 发票信息组件 -->
	<div id="BillInfo">
		<div class="headline">
			<span class="headline_info">发票信息</span
			><span class="headline_rise" @click="billToRecord(16)">开票记录</span>
			<button class="btn">
				<!-- 发票抬头 -->
				<el-button
					class="button"
					type="text"
					@click="dialogFormVisible = true"
					style="color: white; height: 34px"
					>新增发票抬头</el-button
				>
				<el-dialog
					title="新增发票抬头"
					:before-close="handleClose"
					:visible.sync="dialogFormVisible"
				>
					<el-form
						:model="form"
						label-position="right"
						:rules="rules"
						ref="form"
					>
						<el-form-item label="发票类型:" class="resource" prop="resource">
							<el-radio-group v-model="form.resource">
								<el-radio label="个人发票"></el-radio>
								<el-radio label="企业发票"></el-radio>
								<el-radio label="增值税专用发票"></el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="发票抬头:" prop="name">
							<el-input
								v-model="form.name"
								placeholder="请输入发票抬头"
							></el-input>
						</el-form-item>
						<el-form-item
							v-if="this.formFlag == '1' ? false : true"
							label="纳税人识别号:"
							class="region"
							prop="region"
						>
							<el-input
								v-model="form.region"
								placeholder="请输入纳税人识别号"
							></el-input>
						</el-form-item>
						<el-form-item
							v-if="
								this.formFlag == '1'
									? false
									: this.formFlag == '2'
									? false
									: true
							"
							label="注册电话:"
							prop="phone"
						>
							<el-input
								v-model="form.phone"
								placeholder="请输入注册电话"
							></el-input>
						</el-form-item>
						<el-form-item
							v-if="
								this.formFlag == '1'
									? false
									: this.formFlag == '2'
									? false
									: true
							"
							label="注册地址:"
							prop="address"
						>
							<el-input
								v-model="form.address"
								placeholder="请输入注册地址"
							></el-input>
						</el-form-item>
						<el-form-item
							v-if="
								this.formFlag == '1'
									? false
									: this.formFlag == '2'
									? false
									: true
							"
							label="开户银行:"
							prop="BANK"
						>
							<el-input
								v-model="form.BANK"
								placeholder="请输入开户银行"
							></el-input>
						</el-form-item>
						<el-form-item
							v-if="
								this.formFlag == '1'
									? false
									: this.formFlag == '2'
									? false
									: true
							"
							label="银行账户:"
							prop="bankAccount"
						>
							<el-input
								v-model="form.bankAccount"
								placeholder="请输入银行账户"
							></el-input>
						</el-form-item>
						<el-form-item>
							<el-checkbox
								v-model="form.type"
								label="设为默认发票"
								name="type"
							></el-checkbox>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<!-- dialogFormVisible = false -->
						<el-button @click="cancel">取 消</el-button>
						<el-button type="primary" @click="resetForm('form')"
							>确 定</el-button
						>
					</div>
				</el-dialog>
			</button>
		</div>
		<div class="billTitle">
			<span class="billTitle_details">发票抬头</span
			><span class="billTitle_type">发票类型</span
			><span class="billTitle_act">操作</span>
		</div>
		<!-- 渲染发票列表 -->
		<div class="details_info" v-for="(item, index) in invoiceList" :key="index">
			<div class="infoTitle">{{ item.invoiceHead }}</div>
			<div class="bill_type">
				{{
					item.invoiceType == 1
						? '个人发票'
						: item.invoiceType == 2
						? '企业发票'
						: '增值税专用发票'
				}}
			</div>
			<div class="detail_info_open">
				<button class="alter" @click="handleEdit(item)">修改</button>
				<span>|</span>
				<button class="delete" @click="handleDelete(item.id)">删除</button>
				<span
					:class="item.defaultState == 1 ? 'default_back' : 'default'"
					@click="handleDefault(item.id)"
					>{{ item.defaultState == 1 ? '默认发票' : '设为默认' }}</span
				>
			</div>
		</div>
		<div class="hint" v-if="invoiceList.length <= 0">用户暂无发票抬头~</div>
	</div>
</template>

<script>
export default {
	name: 'BillInfo',
	data() {
		// 定义自定义校验
		let EmptyValidator = (rule, value, callback) => {
			if (value.length === 0) {
				callback(new Error('请输入发票抬头'))
			} else {
				let invoiceList = this.invoiceList
				invoiceList.forEach((item, index) => {
					if (item.id == this.invoiceData.id) {
						invoiceList.splice(index, 1)
					}
				})
				if (invoiceList.length != 0) {
					invoiceList.forEach((item) => {
						if (item.invoiceHead == value) {
							return callback(new Error('发票抬头重复'))
						} else {
							callback()
						}
					})
				} else {
					callback()
				}
			}
		}
		return {
			// 用户token
			token: '',
			// 发票列表
			invoiceList: [],
			// 判断标识(是修改发票信息还是新增发票)
			flag: false,
			// 修改发票信息-发票id
			id: '',
			// 标识-是否显示相应的form(1个人2企业3增值)
			formFlag: '1',
			dialogFormVisible: false,
			form: {
				name: '', //发票抬头
				region: '', //纳税人识别号
				phone: '', //注册电话
				address: '', //注册地址
				bankAccount: '', //银行账户
				BANK: '', //开户银行
				resource: '个人发票', //发票类型
				type: false, //发票默认状态(true为默认发票，false非默认发票)
			},
			formLabelWidth: '120px',
			rules: {
				name: [
					{ required: true, message: '请输入发票抬头', trigger: 'blur' },
					{ required: true, validator: EmptyValidator, trigger: 'change' },
				],
				region: [
					{ required: true, message: '请输入纳税人号', trigger: 'blur' },
					{
						min: 15,
						max: 20,
						message: '请输入15~20位纳税人识别号',
						trigger: 'blur',
					},
					{
						pattern: /^\d{15,20}$/,
						message: '请输入正确格式纳税人识别号',
						trigger: 'blur',
					},
				],
				phone: [
					{ required: true, message: '请输入注册电话', trigger: 'blur' },
					{
						pattern: /^1[3|5|7|8|9]\d{9}$/,
						message: '请输入11位正确号码格式',
						trigger: 'change',
					},
				],
				address: [
					{ required: true, message: '请输入注册地址', trigger: 'blur' },
				],
				bankAccount: [
					{ required: true, message: '请输入银行账户', trigger: 'blur' },
					{
						min: 16,
						max: 19,
						message: '请输入16~19位银行账户',
						trigger: 'blur',
					},
					{
						pattern: /^\d{16,19}$/,
						message: '请输入正确格式银行账户',
						trigger: 'blur',
					},
				],
				BANK: [{ required: true, message: '请输入开户银行', trigger: 'blur' }],
				resource: [
					{ required: true, message: '请选择发票类型', trigger: 'change' },
				],
			},
			// 被点击修改-发票信息
			invoiceData: {},
		}
	},
	mounted() {
		this.billInfoInit()
	},
	watch: {
		'form.resource': {
			deep: true,
			handler: function (newV, oldV) {
				if (newV == '个人发票') {
					this.formFlag = '1'
				} else if (newV == '企业发票') {
					this.formFlag = '2'
				} else {
					this.formFlag = '3'
				}
			},
		},
	},
	methods: {
		// 查询发票列表
		billInfoInit() {
			// 获取用户token
			this.token = this.$store.state.userData.token
			this.api.invoiceList({ token: this.token }).then((res) => {
				this.invoiceList = res.data
			})
		},
		// 清空列表
		reset() {
			this.form = {
				name: '', //单位名称
				region: '', //纳税人识别号
				phone: '', //注册电话
				address: '', //注册地址
				bankAccount: '', //银行账户
				BANK: '', //开户银行
				resource: '个人发票', //发票类型
				type: '', //发票状态
			}
		},
		// 新增发票抬头按钮事件
		resetForm(Form) {
			// 存储发票状态标识
			let defaultState
			if (this.$refs[Form].model.type == true) {
				defaultState = 1
			} else {
				defaultState = 2
			}
			// 存储发票类型
			let data = this.$refs[Form].model

			let invoiceType
			if (data.resource == '个人发票') {
				(invoiceType = '1'), (this.formFlag = '1')
			} else if (data.resource == '企业发票') {
				(invoiceType = '2'), (this.formFlag = '2')
			} else {
				invoiceType = '3'
				this.formFlag = '3'
			}
			// 默认发票类型-个人发票
			// this.form.resource = '个人发票'
			this.$refs[Form].validate((valid) => {
				if (valid) {
					this.dialogFormVisible = false
					// 判断是修改发票还是新增发票
					if (this.flag == true) {
						// 修改发票信息
						this.api
							.editInvoice({
								invoiceHead: data.name,
								invoiceType,
								invoiceCode: data.region,
								registerPhone: data.phone,
								registerAddress: data.address,
								depositaryBank: data.BANK,
								bankAccounts: data.bankAccount,
								defaultState,
								token: this.token,
								id: this.id,
							})
							.then((res) => {
								if (res.code == 0) {
									this.$message({
										message: '发票抬头修改成功',
										type: 'success',
									})
									// 改回发票类型-个人
									this.formFlag = '1'
								}
								this.billInfoInit()
							})
						this.flag = false
					} else {
						let formFlag = this.formFlag
						// 新增发票
						this.api
							.insertInvoice({
								invoiceHead: data.name,
								invoiceType,
								invoiceCode: data.region,
								registerPhone: data.phone,
								registerAddress: data.address,
								depositaryBank: data.BANK,
								bankAccounts: data.bankAccount,
								defaultState,
								token: this.token,
							})
							.then((res) => {
								if (res.code == 0) {
									this.$message({
										message: '新增成功',
										type: 'success',
									})
									this.billInfoInit()
									// 改回发票类型-个人
									this.formFlag = '1'
								} else if (res.code == -1) {
									this.$message({
										message: '失败,该发票抬头或识别号已存在',
										type: 'warning',
									})
									this.formFlag = formFlag
								} else {
									this.$message({
										message: res.msg,
										type: 'warning',
									})
									this.formFlag = formFlag
								}
							})
					}
					// 清空form对象
					this.reset()
					return true
				} else {
					return false
				}
			})
		},
		// 弹框取消按钮
		cancel() {
			this.dialogFormVisible = false
			this.reset()
			this.flag = false
		},
		handleEdit(item) {
			this.invoiceData = item
			// 修改发票信息
			this.dialogFormVisible = true
			if (item.defaultState == '1') {
				this.form.type = true
			} else {
				this.form.type = false
			}
			if (item.invoiceType == '1') {
				this.form.resource = '个人发票'
				this.formFlag = '1'
			} else if (item.invoiceType == '2') {
				this.form.resource = '企业发票'
				this.formFlag = '2'
			} else {
				this.form.resource = '增值税专用发票'
				this.formFlag = '3'
			}
			this.form.name = item.invoiceHead
			this.form.region = item.invoiceCode
			this.form.phone = item.registerPhone
			this.form.address = item.registerAddress
			this.form.bankAccount = item.bankAccounts
			this.form.BANK = item.depositaryBank
			// true为修改发票信息，false为新增发票
			this.flag = true
			this.id = item.id
		},
		handleDelete(id) {
			// 删除发票
			this.api
				.deleteInvoice({
					token: this.token,
					id: id,
				})
				.then((res) => {
					// 提示信息
					if (res.code == '10516') {
						return this.$message({
							message: '待开票，暂无法删除！！！',
							type: 'warning',
						})
					}
					this.billInfoInit()
				})
		},
		handleDefault(id) {
			// 更改发票状态
			this.api
				.invoiceUpdateState({
					token: this.token,
					id,
				})
				.then((res) => {
					this.billInfoInit()
				})
		},
		billToRecord(num) {
			// 向父组件传值
			this.$emit('changeBillIR', num)
		},
		// 弹框x按钮
		handleClose(done) {
			this.$confirm('确认关闭？')
				.then((_) => {
					done()
					this.reset()
					this.flag = false
				})
				.catch((_) => {})
		},
	},
}
</script>

<style lang="scss" scoped>
#BillInfo {
	// width: 980px;
	height: 880px;
	background: white;
	position: relative;
	.details_info {
		width: 927px;
		height: 45px;
		line-height: 45px;
		margin: 0 auto;
		border-bottom: 1px solid #eaeaea;
		font-size: 12px;
		font-family: Microsoft YaHei;
		color: #333333;
		display: flex;
		.infoTitle {
			flex: 3;
			text-align: center;
		}
		.bill_type {
			flex: 2;
			text-align: center;
		}
		.detail_info_open {
			flex: 2;
			text-align: center;
			button {
				cursor: pointer;
				font-size: 12px;
				font-family: Microsoft YaHei;
				color: #333333;
			}
			span {
				color: #eaeaea;
				margin-left: 10px;
				margin-right: 10px;
			}
			.delete {
				margin-right: 56px;
			}
			// 默认发票样式
			.default_back {
				box-sizing: border-box;
				padding: 6px 5px;
				background: rgba(208, 208, 208, 0.49);
				border-radius: 3px;
				font-size: 12px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				cursor: hand;
			}
			.default {
				cursor: pointer;
				font-size: 12px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
			}
		}
	}
	.hint {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -70px;
		margin-top: -10px;
		color: #666666;
	}
	.headline {
		height: 60px;
		border-bottom: 1px solid #ededed;
		margin-bottom: 10px;
		.btn {
			cursor: pointer;
			width: 100px;
			height: 34px;
			background: #e1251b;
			border-radius: 3px;
			font-size: 12px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #ffffff;
			margin-left: 672px;
			.button {
				padding: 0;
			}
			// 新增发票抬头组件样式
			::v-deep.el-dialog {
				width: 724px;
				height: 630px;
				.el-dialog__header {
					width: 100%;
					height: 38px;
					padding: 0;
					background: #f4f4f4;
					float: left;
					.el-dialog__title {
						float: left;
						margin-left: 40px;
						line-height: 38px;
						font-size: 14px;
						font-weight: 400;
						color: #000;
					}
					.el-dialog__headerbtn {
						font-size: 20px;
						top: 9px;
						right: 4px;
					}
				}
				.el-dialog__body {
					padding-bottom: 0;
					padding-top: 60px;
					.el-form {
						margin-left: 20px;
						.resource {
							.el-form-item__content {
								.el-radio-group {
									margin-top: 13px;
								}
							}
						}
						.el-form-item {
							.el-form-item__content {
								line-height: 0;
								.el-input {
									width: 452px;
									.el-input__inner {
										width: 452px;
										height: 40px;
									}
								}
								.el-checkbox {
									float: left;
									margin-left: 147px;
								}
								.el-form-item__error {
									margin-left: 150px;
								}
							}
						}
						.region {
							.el-form-item__content {
								.el-input {
									margin-left: -25px;
								}
							}
						}
					}
				}
				.el-dialog__footer {
					.dialog-footer {
						float: left;
						margin-left: 167px;
					}
				}
			}
		}
		.headline_info {
			margin-left: 31px;
			margin-right: 20px;
			line-height: 56px;
			font-size: 16px;
			font-family: Microsoft YaHei;
			color: red;
			display: inline-block;
			border-bottom: 2px solid red;
		}
		.headline_rise {
			line-height: 60px;
			font-size: 16px;
			font-family: Microsoft YaHei;
			cursor: pointer;
		}
	}
	.billTitle {
		background-color: #f4f4f4;
		width: 927px;
		height: 42px;
		margin: 0 auto;
		margin-bottom: 11px;
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		display: flex;
		.billTitle_details {
			flex: 3;
			text-align: center;
			line-height: 42px;
		}
		.billTitle_type {
			flex: 2;
			text-align: center;
			line-height: 42px;
		}
		.billTitle_act {
			flex: 2;
			text-align: center;
			line-height: 42px;
		}
	}
}
</style>