<template>
	<div class="userCenter_box">
		<div class="user_box">
			<div class="title flex-center">用户中心</div>
			<div class="userCenter flex">
				<div class="userCenter_fl flex">
					<div class="userImg" @click="changeApprove(17)">
						<img :src="imgUrl" alt="" />
					</div>
					<div class="userName_box">
						<div class="userName">
							{{ userInfo.nickName ? userInfo.nickName : userInfo.phone }}
						</div>
						<div class="authentication flex">
							<div
								class="personage display gr"
								@click="changeApprove(18, 'second')"
								v-if="
									userInfo.userType == '00' ||
									userInfo.userType == '02' ||
									userInfo.userType == '' ||
									userInfo.userType == '0'
								"
							>
								<img src="../img/gr.png" alt="" />
								个人认证
							</div>
							<div
								class="personage display"
								@click="changeApprove(18, 'first')"
								v-if="
									userInfo.userType == '01' ||
									userInfo.userType == '02' ||
									userInfo.userType == '' ||
									userInfo.userType == '0'
								"
							>
								<img src="../img/qy.png" alt="" />
								企业认证
							</div>
						</div>
					</div>
				</div>
				<div class="userCenter_fr Between">
					<div class="integral_list display column" @click="changePBTC(11)">
						<div class="num">{{ userInfo.integral }}</div>
						<div class="label">积分</div>
					</div>
					<div class="integral_list display column" @click="changePBTC(10)">
						<div class="num">
							{{ userInfo.couponCount == null ? 0 : userInfo.couponCount }}
						</div>
						<div class="label">优惠券</div>
					</div>
					<div class="integral_list display column" @click="changePBTC(8)">
						<div class="num">{{ userInfo.collectCount }}</div>
						<div class="label">收藏</div>
					</div>
					<div class="integral_list display column" @click="changePBTC(12)">
						<div class="num">{{ userInfo.bonus }}</div>
						<div class="label">奖金</div>
					</div>
				</div>
			</div>
		</div>
		<div class="myOrder">
			<div class="title">我的订单</div>
			<div class="order_status_box flex">
				<div
					class="order_status_list list display pointer"
					@click="changeBtn(1)"
				>
					<div class="status_img">
						<img src="../img/1.png" alt="" />
					</div>
					<div class="order_status_num">
						<div class="num">{{ SSPANum.stayPaymentNum }}</div>
						<div class="orderStatus">待付款</div>
					</div>
				</div>
				<div
					class="order_status_list_s list display pointer"
					@click="changeBtn(2)"
				>
					<div class="status_img">
						<img src="../img/2.png" alt="" />
					</div>
					<div class="order_status_num">
						<div class="num">{{ SSPANum.stayShipmentsNum }}</div>
						<div class="orderStatus">待发货</div>
					</div>
				</div>
				<div
					class="order_status_list_ss list display pointer"
					@click="changeBtn(3)"
				>
					<div class="status_img">
						<img src="../img/3.png" alt="" />
					</div>
					<div class="order_status_num">
						<div class="num">{{ SSPANum.pendingReceiptNum }}</div>
						<div class="orderStatus">待收货</div>
					</div>
				</div>
				<div
					class="order_status_list_sss list display pointer"
					@click="changeBtn(7)"
				>
					<div class="status_img">
						<img src="../img/4.png" alt="" />
					</div>
					<div class="order_status_num">
						<div class="num">{{ SSPANum.afterSalesNum }}</div>
						<div class="orderStatus">退换/售后</div>
					</div>
				</div>
				<div
					class="order_status_list_ssss list display pointer"
					@click="changeBtn(99)"
				>
					<div class="allOrder">全部订单></div>
				</div>
			</div>
			<div class="order">
				<OrderList :orderstate="orderstate"></OrderList>
			</div>
		</div>
	</div>
</template>
<script>
import OrderList from './orderList.vue'
export default {
	props: {
		orderType: {},
	},
	components: {
		OrderList,
	},
	data() {
		return {
			// 个人信息
			userInfo: {},
			orderstate: '',
			// 用户默认头像
			imgUrl: '',
			// 订单数量
			SSPANum: {},
		}
	},
	watch: {
		orderType: {
			handler(n, o) {
				this.orderstate = n
			},
			//   immediate: true,
			//   deep: true // 深度监听父组件传过来对象变化
		},
	},
	mounted() {
		this.getUserInfo()
		//  默认头像
		this.imgUrl = require('../img/userImg.png')
	},
	methods: {
		getUserInfo() {
			//获取用户信息
			this.api
				.findUserInfo({ token: this.$store.state.userData.token })
				.then((res) => {
					if (res.code == 0) {
						if (res.data.avatar == '') {
							this.imgUrl = require('../img/userImg.png')
						} else {
							this.imgUrl = res.data.avatar
						}
						this.userInfo = res.data
					}
				})
			//  获取订单数量
			this.api
				.findOrderNum({ token: this.$store.state.userData.token })
				.then((res) => {
					if (res.code == 0) {
						this.SSPANum = res.data
					}
				})
		},
		changeBtn(id) {
			this.$emit('changeBtn', id)
		},
		changePBTC(id) {
			this.$emit('changePBTC', id)
		},
		changeApprove(id, dat) {
			this.$emit('changeApprove', id, dat)
		},
	},
}
</script>
<style lang="scss" scoped >
.userCenter_box {
	.user_box {
		background: white;
		.title {
			width: 100%;
			height: 62px;
			color: #e1251b;
			padding-left: 30px;
			box-sizing: border-box;
			border-bottom: 1px solid #eaeaea;
		}
		.userCenter {
			width: 100%;
			height: 196px;
			.userCenter_fl {
				width: 476px;
				padding-left: 37px;
				box-sizing: border-box;
				border-right: 1px solid #eaeaea;
				.userImg {
					width: 118px;
					margin-top: 44px;
					cursor: pointer;
					img {
						width: 118px;
						height: 118px;
						border-radius: 50%;
					}
				}
				.userName_box {
					margin-top: 59px;
					margin-left: 20px;
					.userName {
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
					}
					.authentication {
						margin-top: 20px;
						.personage {
							width: 108px;
							height: 30px;
							border-radius: 15px;
							font-size: 14px;
							color: #ffffff;
							background: #0068c1;
							cursor: pointer;
							img {
								width: 18px;
								height: 20px;
								margin-right: 6px;
							}
						}
						.gr {
							background: #f29a00;
							margin-right: 10px;
							img {
								width: 19px;
								height: 22px;
							}
						}
					}
				}
			}
			.userCenter_fr {
				width: 503px;
				height: 196px;
				padding-left: 60px;
				padding-right: 67px;
				.integral_list {
					cursor: pointer;
					.num {
						font-size: 24px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
					}
					.label {
						font-size: 16px;
						color: #666666;
					}
				}
			}
		}
	}
	.myOrder {
		width: 980px;
		background: white;
		padding: 20px 30px;
		box-sizing: border-box;
		border-top: 20px solid #f4f4f4;
		.title {
			font-size: 18px;
			color: #333333;
		}
		.order_status_box {
			margin-top: 20px;
			border: 1px solid #eaeaea;
			.order_status_list {
				border-left: none;
			}
			.list {
				width: 181px;
				height: 85px;
				border-left: 1px solid #eaeaea;
				.status_img {
					img {
						width: 44px;
						height: 44px;
					}
				}
				.order_status_num {
					margin-left: 22px;
					.num {
						font-size: 24px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 24px;
					}
					.orderStatus {
						font-size: 14px;
						color: #333333;
						margin-top: 9px;
					}
				}
			}
			.order_status_list_s {
				width: 185px;
				.status_img {
					img {
						width: 47px;
						height: 47px;
					}
				}
			}
			.order_status_list_ss {
				width: 202px;
				.status_img {
					img {
						width: 49px;
						height: 47px;
					}
				}
			}
			.order_status_list_sss {
				.status_img {
					img {
						width: 42px;
						height: 47px;
					}
				}
			}
			.order_status_list_ssss {
				.allOrder {
					font-size: 18px;
					color: #333333;
				}
			}
		}
	}
}
</style>
