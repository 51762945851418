<template>
	<div class="selectInvoice">
		<div class="mask"></div>
		<div class="invoice_details">
			<div class="invoice_head Between">
				<div class="title">选择发票抬头</div>
				<i class="el-icon-close display pointer" @click="close"></i>
			</div>
			<div class="invoice_form">
				<el-table
					:data="invoiceList"
					style="width: 100%"
					row-key="id"
					lazy
					max-height="250"
				>
					<el-table-column
						label="发票ID"
						width="80"
						align="center"
						fixed="left"
					>
						<template slot-scope="scope">
							<el-radio
								v-model="invoiceList.id"
								:label="scope.row.id"
								@change="handleSelectChange(scope.row)"
							></el-radio>
						</template>
					</el-table-column>
					<el-table-column prop="invoiceHead" label="发票抬头" align="center">
					</el-table-column>
					<el-table-column prop="name" label="发票类型" align="center">
						<template slot-scope="scope">
							<div v-if="scope.row.invoiceType == 1">个人发票</div>
							<div v-if="scope.row.invoiceType == 2">企业发票</div>
							<div v-if="scope.row.invoiceType == 3">增值税专用发票</div>
						</template>
					</el-table-column>
					<el-table-column prop="address" label="操作" align="center">
						<template slot-scope="scope">
							<el-button size="small" @click="modifyInvoiceBtn(scope.row)"
								>修改</el-button
							>
							<el-button size="small" @click="delInvoiceBtn(scope.row.id)"
								>删除</el-button
							>
							<el-button
								v-if="scope.row.defaultState == 1"
								type="primary"
								size="small"
								>默认发票</el-button
							>
							<el-button
								v-if="scope.row.defaultState == 2"
								size="small"
								@click="changeBtn(scope.row)"
							>
								设置默认
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="operating flex">
					<div class="confirm pointer display" @click="confirm">确定</div>
					<div class="close pointer display" @click="close">取消</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		invoiceList: {},
	},
	data() {
		return {
			invoiceId: null,
		}
	},
	mounted() {},
	methods: {
		//设置默认发票
		changeBtn(row) {
			let params = {
				id: row.id,
				token: this.$store.state.userData.token,
			}
			this.api.invoiceUpdateState(params).then((res) => {
				if (res.code == 0) {
					this.$emit('updateInvoice')
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		// 修改
		modifyInvoiceBtn(row) {
			this.$emit('modifyInvoice', row)
		},
		//删除发票
		delInvoiceBtn(id) {
			let params = {
				id: id,
				token: this.$store.state.userData.token,
			}
			this.api.deleteInvoice(params).then((res) => {
				if (res.code == 0) {
					this.$emit('updateInvoice')
					this.$message.success('删除成功')
				} else {
					this.$message
				}
			})
		},
		// 选择
		handleSelectChange(row) {
			this.invoiceId = row.id
		},
		//关闭
		close() {
			this.$emit('close')
		},
		confirm() {
			if (this.invoiceId == null) {
				return this.$message.error('请选择发票')
			}
			this.$emit('confirm', this.invoiceId)
		},
	},
}
</script>
<style lang="scss" scoped>
.selectInvoice {
	.invoice_details {
		width: 800px;
		background: #fff;
		position: fixed;
		top: 50%;
		left: 50%;
		margin-left: -400px;
		margin-top: -200px;
		z-index: 10;

		.invoice_head {
			width: 800px;
			height: 38px;
			background: #f4f4f4;
			padding-left: 30px;
			padding-right: 20px;

			.title {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
			}

			.el-icon-close {
				font-size: 24px;
				color: #8f8f8f;
			}
		}

		.invoice_form {
			padding: 22px 28px;
			box-sizing: border-box;
		}
	}
	.operating {
		justify-content: flex-end;
		div {
			width: 80px;
			height: 36px;
			border: 1px solid #ededed;
			margin-top: 10px;
			border-radius: 4px;
			margin-left: 20px;
			font-size: 14px;
		}
		.confirm {
			color: #fff;
			background-color: #e62129;
			border-color: #e62129;
		}
	}
}
</style>