<template>
	<div class="orderCenter">
		<div class="order_state_head Between">
			<div class="order_state_list flex-center pointer">
				<div
					class="name display"
					@click="changeBtn(99)"
					:class="activeIndex == 99 ? 'orderActive' : ''"
				>
					全部订单
					<div class="lines" v-if="activeIndex == ''"></div>
				</div>
				<div
					class="name display"
					@click="changeBtn(item.id)"
					:class="activeIndex == item.id ? 'orderActive' : ''"
					v-for="(item, index) in orderStateData"
					:key="index"
				>
					{{ item.name }}
					<div class="lines" v-if="activeIndex == item.id"></div>
				</div>
			</div>
			<div class="search_box flex-center">
				<input
					@focus="getFocus"
					v-model="value"
					type="text"
					placeholder="请输入"
				/>
				<div class="searchBtn display pointer" @click="searcGoodsBtn">
					<img src="../img/search.png" alt="" />
				</div>
			</div>
		</div>
		<div class="OrderList_box">
			<OrderList :orderstate="activeIndex" :goodsName="goodsName"></OrderList>
		</div>
	</div>
</template>
<script>
import OrderList from './orderList.vue'
export default {
	props: {
		orderType: {},
	},
	components: {
		OrderList,
	},
	data() {
		return {
			value: '',
			activeIndex: '',
			goodsName: '',
			orderStateData: [
				{
					id: 1,
					name: '待付款',
				},
				{
					id: 2,
					name: '待发货',
				},
				{
					id: 3,
					name: '待收货',
				},
				{
					id: 4,
					name: '待评价',
				},
			],
		}
	},
	watch: {
		orderType: {
			handler(n, o) {
				this.activeIndex = n
			},
			//   immediate: true,
			//   deep: true // 深度监听父组件传过来对象变化
		},
	},
	mounted() {
		this.activeIndex = this.orderType
	},
	methods: {
		getFocus() {
			this.$keyBoard(this, 'searcGoodsBtn', 13)
		},
		changeBtn(id) {
			this.activeIndex = id
		},
		searcGoodsBtn() {
			this.goodsName = this.value
		},
	},
}
</script>
<style lang="scss" scoped>
.orderCenter {
	background: white;
	.order_state_head {
		width: 100%;
		height: 62px;
		padding: 0px 30px;
		box-sizing: border-box;
		border-bottom: 1px solid #eaeaea;
		.name {
			height: 62px;
			font-size: 16px;
			color: #333;
			position: relative;
			margin-right: 30px;
			.lines {
				width: 100%;
				height: 2px;
				background: #e62129;
				position: absolute;
				left: 0px;
				bottom: 0px;
			}
		}
		.orderActive {
			color: #e62129 !important;
		}
		.search_box {
			width: 300px;
			height: 36px;
			background: #ffffff;
			border: 1px solid #eaeaea;
			border-radius: 3px;
			input {
				width: 244px;
				height: 34px;
				padding-left: 20px;
				box-sizing: border-box;
			}
			.searchBtn {
				width: 56px;
				height: 36px;
				background: #d0d0d0;
				border-radius: 0px 3px 3px 0px;
				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
	.OrderList_box {
		padding: 20px 30px;
	}
}
</style>