<template>
    <!-- 联系客服组件 -->
    <div id="service">
        <div class="title">
            <span>联系客服</span>
        </div>
        <div class="service_header">
            <div class="service_onLine">
                <p class="onLineService">在线客服</p>
                <div class="onLineService_text">
                    <p>7*24h在线解答</p>
                    <p>随时随地询问，更高效更便捷</p>
                </div>
                <button>点击咨询</button>
            </div>
            <div class="service_phone">
                <p class="phoneService">电话客服</p>
                <div class="phoneService_text">
                    <p>致电客服，人工解答</p>
                    <p>客服热线：400-698-6665</p>
                </div>
            </div>
        </div>
        <div class="serviceDetail">
            <div class="HQ">
                <img src="../../../assets/img/person/site.png" alt="" />
                <p class="HQ_site">总部地址</p>
                <p class="HQ_site_detail">{{ this.serviceInfo.headquartersAddress }}</p>
                <p class="HQ_map" @click="toMap">查看地图</p>
            </div>
            <div class="HQ">
                <img src="../../../assets/img/person/fax.png" alt="" />
                <p class="HQ_site">传　　真</p>
                <p class="HQ_site_detail">{{ this.serviceInfo.fax }}</p>
            </div>
            <div class="HQ">
                <img src="../../../assets/img/person/phone.png" alt="" />
                <p class="HQ_site">电　　话</p>
                <p class="HQ_site_detail">{{ this.serviceInfo.telephone }}</p>
            </div>
            <div class="HQ">
                <img src="../../../assets/img/person/Email.png" alt="" />
                <p class="HQ_site">邮　　箱</p>
                <p class="HQ_site_detail">{{ this.serviceInfo.email }}</p>
            </div>
            <div class="HQ">
                <img src="../../../assets/img/person/dawk.png" alt="" />
                <p class="HQ_site">邮政编码</p>
                <p class="HQ_site_detail">{{ this.serviceInfo.zipCode }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import WARBLER from '@/http/yingyu'
export default {
    name: 'Service',

    data() {
        return {
            // 平台信息
            serviceInfo: {},
        }
    },

    mounted() {
        this.serviceInit()
        this.init()
    },

    methods: {
        init() {
            WARBLER.init({
                WARBLER_URL: 'https://www.yingyuchat.com',
                WARBLER_KEFU_ID: 'zhushou413',
                WARBLER_ENT: '591',
                WARBLERAUTO_SHOW: false, //对话框默认一开始就显示，false则为不显示
            })
        },
        // 查询平台信息
        async serviceInit() {
            await this.api.selectInfo().then((res) => {
                this.serviceInfo = res.data
            })
        },
        // 查看公司地图
        toMap() {
            window.open(
                'http://api.map.baidu.com/geocoder?output=html&location=22.694852,114.304925',
                '_blank'
            )
        },
    },
}
</script>

<style lang="scss" scoped>
#service {
    height: 880px;
    background-color: white;

    .title {
        height: 60px;
        line-height: 60px;
        border-bottom: 1px solid #eaeaea;
        margin-bottom: 25px;

        span {
            width: 65px;
            height: 17px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #e1251b;
            line-height: 30px;
            margin-left: 45px;
        }
    }

    .service_header {
        width: 930px;
        height: 314px;
        background-color: #f9f9f9;
        margin: 0 auto;
        display: flex;

        .service_onLine {
            margin-left: 74px;
            margin-top: 63px;

            .onLineService {
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
            }

            .onLineService_text {
                width: 208px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 30px;
                margin-top: 30px;
            }

            button {
                width: 134px;
                height: 40px;
                background: #e1251b;
                border-radius: 3px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                cursor: pointer;
                margin-top: 50px;
            }
        }

        .service_phone {
            margin-left: 182px;
            margin-top: 63px;

            .phoneService {
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
            }

            .phoneService_text {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 30px;
                margin-top: 30px;
            }
        }
    }

    .serviceDetail {
        margin-left: 100px;

        .HQ {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 25px;

            img {
                width: 40px;
                height: 40px;
                margin-right: 28px;
            }

            .HQ_site {
                color: #333333;
                font-size: 16px;
                font-weight: Bold;
                margin-right: 48px;
            }

            .HQ_site_detail {
                font-size: 16px;
                color: #666;
                font-weight: Regular;
            }

            .HQ_map {
                font-size: 16px;
                color: #e1251b;
                font-weight: Regular;
                cursor: pointer;
                margin-left: 45px;
            }
        }
    }
}</style>