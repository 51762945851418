<template>
	<!-- 我的奖金组件 -->
	<div id="Bonus">
		<div class="headline">
			<span class="headline_part">我的奖金</span>
		</div>
		<div class="validPart">
			<div>
				可用提现奖金：<span>{{ userInfo.bonus }}</span
				><span class="freeze"
					>（可用￥{{ userInfo.bonus - userInfo.frozenBonus }} 冻结￥{{
						userInfo.frozenBonus
					}}）</span
				>
				<el-button type="text" @click="dialogFormVisible = true"
					>提现</el-button
				>
			</div>
			<!-- 提现按钮 -->
			<el-dialog title="奖金提现" :visible.sync="dialogFormVisible">
				<el-form :model="form" ref="bonus" :rules="rules">
					<el-form-item
						label="提现金额:"
						prop="name"
						:label-width="formLabelWidth"
					>
						<el-input
							v-model="form.withdrawMoney"
							@input="checkInput"
							placeholder="请输入提现金额"
							autocomplete="off"
						></el-input>
					</el-form-item>
					<el-form-item
						label="微信号:"
						prop="desc"
						:label-width="formLabelWidth"
					>
						<el-input
							v-model="form.weChatCode"
							placeholder="请输入微信号"
							autocomplete="off"
						></el-input>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="bonusForm('bonus')">提交</el-button>
					<el-button @click="dialogFormVisible = false">取 消</el-button>
				</div>
			</el-dialog>
		</div>
		<div class="navBar flex-center">
			<div
				class="btn display pointer"
				:class="activeIndex == item.id ? 'active' : ''"
				v-for="(item, index) in navBarData"
				:key="index"
				@click="chageBtn(item.id)"
			>
				{{ item.name }}
				<div class="line" v-if="activeIndex == item.id"></div>
			</div>
		</div>
		<div class="title">
			<div id="partBonus" v-if="activeIndex == 1">
				<div class="line">
					<el-dropdown @command="handleCommand">
						<span class="el-dropdown-link">
							{{ showCommand }}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<!--  disabled禁选标识 -->
							<el-dropdown-item
								v-if="showCommand == '全部' ? false : true"
								command="全部"
								>全部</el-dropdown-item
							>
							<el-dropdown-item
								v-if="showCommand == '近三个月' ? false : true"
								command="近三个月"
								>近三个月</el-dropdown-item
							>
							<el-dropdown-item
								v-if="showCommand == '今年内' ? false : true"
								command="今年内"
								>今年内</el-dropdown-item
							>
						</el-dropdown-menu>
					</el-dropdown>
					<span>收入/提现</span>
					<span>收入/提现可用奖金</span>
					<span>详细说明</span>
				</div>
				<div class="showtasks" v-for="(item, index) in tasks" :key="index">
					<span>{{ item.createTime }}</span>
					<span :class="item.status == 0 ? 'num red' : 'num'">{{
						item.status == 0 ? '+' + item.price : '-' + item.price
					}}</span>
					<span class="num">{{ '可用' + item.surplusMoney }}</span>
					<!-- 1-下线返利,2-购物消费,3-提现,4-退款,5-注册，6-邀请，7-产品被购买 -->
					<span v-if="item.type == 1">下线返利{{ item.orderCode }}</span>
					<span v-if="item.type == 2">购物消费{{ item.orderCode }}</span>
					<span v-if="item.type == 3">提现{{ item.orderCode }}</span>
					<span v-if="item.type == 4">退款{{ item.orderCode }}</span>
					<span v-if="item.type == 5">注册{{ item.orderCode }}</span>
					<span v-if="item.type == 6">邀请{{ item.orderCode }}</span>
					<span v-if="item.type == 7">产品被购买{{ item.orderCode }}</span>
				</div>
			</div>
			<div id="partBonus" v-else>
				<div class="line">
					<span>提取时间</span>
					<span>提取奖金</span>
					<span>剩余奖金</span>
					<span>微信号</span>
					<span>审核状态</span>
				</div>
				<div class="showtasks" v-for="(item, index) in tasks" :key="index">
					<span>{{ item.applyTime }}</span>
					<span>-{{ item.withdrawMoney }}</span>
					<span>{{ item.surplusMoney }}</span>
					<span>{{ item.weChatCode }}</span>
					<span v-if="item.auditState == 1">待审核</span>
					<span v-if="item.auditState == 2">审核通过</span>
					<span v-if="item.auditState == 3">审核不通过</span>
					<span v-if="item.auditState == 4">已付款</span>
				</div>
			</div>
		</div>
		<div class="pageBox" v-if="total > 0">
			<Pages :total="total" :params="params" @jump="pageSkip"></Pages>
		</div>
	</div>
</template>

<script>
import Pages from '@/components/pages.vue'
export default {
	components: {
		Pages,
	},
	name: 'Bonus',
	data() {
		return {
			activeIndex: 1,
			userInfo: {},
			navBarData: [
				{
					id: 1,
					name: '奖金记录',
				},
				{
					id: 2,
					name: '提现记录',
				},
			],
			showCommand: '全部',
			// 表格数据
			tasks: [],
			// 总奖金
			bonusNum: '0',
			// 提现按钮
			dialogFormVisible: false,
			// 提现参数
			form: {
				token: this.$store.state.userData.token,
				withdrawMoney: '',
				weChatCode: '',
			},
			rules: {
				withdrawMoney: [
					{ required: true, message: '请输入提现金额', trigger: 'change' },
				],
				weChatCode: [
					{ required: true, message: '请输入微信号', trigger: 'change' },
				],
			},
			formLabelWidth: '120px',
			params: {
				token: this.$store.state.userData.token,
				page: 1,
				limit: 10,
				getTime: '',
			},
			total: 0,
		}
	},

	mounted() {
		this.bonusInit()
		this.getUserInfo()
	},

	methods: {
		//获取用户信息
		getUserInfo() {
			this.api
				.findUserInfo({ token: this.$store.state.userData.token })
				.then((res) => {
					if (res.code == 0) {
						this.userInfo = res.data
					}
				})
		},
		// 获取用户奖金列表
		bonusInit() {
			this.api.selectByUserId(this.params).then((res) => {
				this.tasks = res.data
				this.total = res.count
			})
		},
		// 获取用户提取奖金记录
		getWithdrawRecord() {
			this.api.findPageWithdrawRecord(this.params).then((res) => {
				this.total = res.count
				this.tasks = res.data
			})
		},
		// 下拉菜单
		handleCommand(command) {
			this.showCommand = command
			if (command == '近三个月') {
				this.params.getTime = 1
				this.bonusInit()
			} else if (command == '今年内') {
				this.params.getTime = 2
				this.bonusInit()
			} else {
				this.params.getTime = ''
				this.bonusInit()
			}
		},
		// 只能输入两位小数
		checkInput() {
			this.form.withdrawMoney = this.dealInputVal(this.form.withdrawMoney)
		},
		// 只能输入两位小数
		dealInputVal(value) {
			value = value.replace(/^0*(0\.|[1-3])/, '$1')
			value = value.replace(/[^\d.]/g, '') //清除"数字"和"."以外的字符
			value = value.replace(/^\./g, '') //验证第一个字符是数字而不是字符
			value = value.replace(/\.{1,}/g, '.') //只保留第一个.清除多余的
			value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
			value = value.replace(/^(\-)*(\d*)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
			value =
				value.indexOf('.') > 0
					? value.split('.')[0].substring(0, 6) + '.' + value.split('.')[1]
					: value.substring(0, 6)
			return value
		},
		// 提交按钮
		bonusForm(Form) {
			this.$refs[Form].validate((valid) => {
				if (valid) {
					if (this.form.withdrawMoney <= 0) {
						return this.$message.error('提现金额不可小于0')
					}
					// 提现成功
					this.api.addWithdrawRecord(this.form).then((res) => {
						if (res.code == 0) {
							this.$message.success('提交成功')
							this.dialogFormVisible = false
							if (this.activeIndex == 1) {
								this.bonusInit()
							} else {
								this.getWithdrawRecord()
							}
						}
					})

					return true
				} else {
					return false
				}
			})
		},
		chageBtn(id) {
			this.activeIndex = id
			this.params.page = 1
			if (id == 1) {
				this.bonusInit()
			} else {
				this.getWithdrawRecord()
			}
		},
		//分页
		pageSkip(page) {
			this.params = page
			if (this.activeIndex == 1) {
				this.bonusInit()
			} else {
				this.getWithdrawRecord()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
#Bonus {
	min-height: 880px;
	background-color: white;
	.headline {
		height: 60px;
		border-bottom: 1px solid #ededed;
		margin-bottom: 17px;
		.headline_part {
			margin-left: 41px;
			line-height: 60px;
			font-size: 16px;
			font-family: Microsoft YaHei;
			color: #e62129;
		}
	}
	.validPart {
		margin: 0 auto;
		width: 924px;
		height: 96px;
		background: #f4f4f4;
		line-height: 96px;
		div {
			margin-left: 41px;
			font-size: 20px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #666666;
			.freeze {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
			}
			// 提现按钮
			.el-button {
				width: 119px;
				height: 44px;
				background: #e1251b;
				border-radius: 5px;
				font-size: 20px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #ffffff;
				margin-right: 29px;
				margin-top: 28px;
				float: right;
			}
		}
		span {
			margin-left: 18px;
			font-size: 36px;
			line-height: 30px;
			font-family: Arial;
			font-weight: 400;
			color: #e1251b;
		}
		::v-deep.el-dialog__wrapper {
			.el-dialog {
				width: 724px;
				height: 372px;
				margin: 0 auto;
				.el-dialog__header {
					width: 724px;
					height: 38px;
					background-color: #f4f4f4;
					.el-dialog__title {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #000000;
						left: 35px;
						top: 7px;
						position: absolute;
					}
					.el-dialog__headerbtn {
						top: 6px;
						right: 12px;
						font-size: 25px;
					}
				}
				.el-dialog__body {
					margin: 0 auto;
					width: 580px;
					height: 175px;
					padding-top: 65px;
					.el-form {
						.el-form-item {
							.el-form-item__content {
								.el-input {
									.el-input__inner {
										width: 341px;
										height: 40px;
										margin-left: 40px;
									}
								}
								.el-form-item__error {
									left: 40px;
								}
							}
						}
					}
				}
				.el-dialog__footer {
					text-align: left;
					margin-left: 232px;
					.dialog-footer {
						.el-button--primary {
							width: 100px;
							height: 40px;
							background: #e1251b;
							border-radius: 3px;
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #ffffff;
							border: none;
						}
						.el-button--default {
							width: 100px;
							height: 40px;
							background: #f4f4f4;
							border-radius: 3px;
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #333333;
						}
					}
				}
			}
		}
	}
	.navBar {
		width: 924px;
		height: 60px;
		padding-left: 40px;
		box-sizing: border-box;
		.active {
			color: #e62129 !important;
		}
		.btn {
			height: 60px;
			padding-top: 6px;
			position: relative;
			font-size: 16px;
			color: #666666;
			margin-right: 40px;
			.line {
				width: 65px;
				height: 2px;
				background: #e62129;
				position: absolute;
				left: 0;
				bottom: 0;
			}
		}
	}
	.title {
		width: 924px;
		margin: 0 auto;
		text-align: center;
		#partBonus {
			width: 924px;
			margin: 0 auto;
			.line {
				height: 42px;
				line-height: 42px;
				background-color: #f4f4f4;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #666666;
				display: flex;
				.el-dropdown {
					flex: 1;
				}
				span {
					flex: 1;
				}
			}
			.showtasks {
				height: 42px;
				line-height: 42px;
				border-bottom: 1px solid #f4f4f4;
				font-size: 12px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				display: flex;
				span {
					flex: 1;
				}
				.num {
					color: #666666;
					font-size: 15px;
				}
				.red {
					color: red;
				}
			}
		}
	}
}
</style>